import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useGender = () => {
  const { t } = useTranslation("ucet");

  return useMemo(() => {
    return {
      genderName: (g: string) => {
        if (g === "M") {
          return t("common.gender.M", "Muž");
        } else if (g === "F") {
          return t("common.gender.F", "Žena");
        } else {
          return t("common.gender.other", "Jiné / neuvedeno");
        }
      },
      genders: ["M", "F", "O"],
    };
  }, [t]);
};
