import { Box, Typography } from "@mui/material";
import { AlertsContext } from "@sista/dista-ui";
import { IdmClientContext } from "@sista/idm-client";
import {
  Center,
  SistaCodeInput,
  SistaDialog,
  SmartButton,
  useSafeSistaAsync,
  VerifyAccountEmailDTO,
} from "@sista/library-stasi";
import { useCallback, useContext } from "react";
import { useForm } from "react-hook-form";
import { FormContainer } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { useIdmClient } from "../../../api/utils/useIdmClient";
import { TextLanguageSwitcher } from "../../ui/TextLanguageSwitcher";

export const VerifyNiaAccountDialog = () => {
  const form = useForm<VerifyAccountEmailDTO, unknown>();
  const { loggedAccount, setLoggedAccount } = useContext(IdmClientContext);
  const { t } = useTranslation("ucet");
  const { resolveNiaEmailVerificationNeeded } = useIdmClient();
  const { showSuccess, showError } = useContext(AlertsContext);

  const verifyNiaAccountImpl = useCallback(
    async (dto: VerifyAccountEmailDTO) => {
      const request = {
        value: dto.verificationToken,
      };
      const la = await resolveNiaEmailVerificationNeeded(request);
      if (la != null) {
        setLoggedAccount(la);
        showSuccess(
          t(
            "ucet.registration.verificationSuccess",
            "Aktivace proběhla úspěšně"
          )
        );
      } else {
        showError(
          t(
            "ucet.registration.verificationError",
            "Aktivace se nezdařila. Kód není správně nebo již vypršel, zkuste si ho nechat zaslat znovu."
          )
        );
      }
    },
    [
      resolveNiaEmailVerificationNeeded,
      setLoggedAccount,
      showError,
      showSuccess,
      t,
    ]
  );

  const [verifyNiaAccountCall, verifyNiaAccountState] =
    useSafeSistaAsync(verifyNiaAccountImpl);

  // const resendCodeImpl = useCallback(async () => {
  //   await idmClient.resendEmailVerificationCode(null);
  //   showSuccess(
  //     t(
  //       "ucet.registration.verifyResendCodeSuccess",
  //       "Znovuzaslání kódu se podařilo"
  //     )
  //   );
  // }, [idmClient, showSuccess, t]);
  //
  // const [resendCodeCall, resendCodeState] = useSafeSistaAsync(resendCodeImpl);

  return (
    <SistaDialog
      onClose={() => null}
      title={t("ucet.registration.verifyDialogTitle", "Aktivace účtu")}
    >
      <Typography marginBottom={2} typography={"h4"}>
        {t("ucet.registration.verifyTitle", "Aktivační kód")}
      </Typography>
      <Typography marginBottom={4} typography={"body1"}>
        {t(
          "ucet.registration.verifySubtitle",
          "Váš účet byl vytvořen. Na zadanou e-mailovou adresu jsme odeslali šestimístný aktivační kód, kterým ověříme správnost zadané e-mailové adresy."
        )}
      </Typography>

      <FormContainer
        defaultValues={{}}
        onSuccess={verifyNiaAccountCall}
        formContext={form}
      >
        {/*<BlockLeave/>*/}

        <Center>
          <SistaCodeInput name="verificationToken" />
        </Center>

        <Box sx={{ marginTop: 4, textAlign: "right" }}>
          {/*<SmartButton*/}
          {/*  state={resendCodeState}*/}
          {/*  primary={false}*/}
          {/*  onClick={resendCodeCall}*/}
          {/*>*/}
          {/*  {t("ucet.registration.resendVerifyButton", "Zaslat znovu")}*/}
          {/*</SmartButton>*/}
          <SmartButton state={verifyNiaAccountState}>
            {t("ucet.registration.verifyButton", "Aktivovat")}
          </SmartButton>
        </Box>

        <Box marginTop={4}>
          <TextLanguageSwitcher />
        </Box>
      </FormContainer>
    </SistaDialog>
  );
};
