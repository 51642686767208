import { isProd } from "@sista/library-stasi";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export const RedirectRouterScreen = () => {
  const { target } = useParams();

  useEffect(() => {
    switch (target) {
      case "isrb2":
        window.location.href = isProd()
          ? "https://isrb2.tacr.cz"
          : "https://test.isrb2.tacr.cz";
        break;
      case "isrb3":
        window.location.href = isProd()
          ? "https://isrb3.tacr.cz"
          : "https://test.isrb3.tacr.cz";
        break;
      case "pruvodce":
        window.location.href = isProd()
          ? "https://pruvodce.beta.tacr.cz"
          : "https://test.pruvodce.beta.tacr.cz";
        break;
      case "ista":
        window.location.href = isProd()
          ? "https://ista.tacr.cz/ISTA/PUBLIC/oauth.jsp?type=1"
          : "https://test.ista.tacr.cz/ISTA/PUBLIC/oauth.jsp?type=1";
        break;
    }
  }, [target]);

  return <></>;
};
