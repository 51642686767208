import MailOutlineIcon from "@mui/icons-material/MailOutline";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { theme } from "@sista/dista-ui";
import { ucetAppConfig } from "@sista/idm-client";
import {
  Center,
  SistaCard,
  SistaFormHeader,
  UiContext,
} from "@sista/library-stasi";
import React, { useCallback, useContext, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { featureNiaLogin } from "../../utils/features";
import GoogleButton from "../ui/GoogleButton";
import { IstaLoginInfo } from "../ui/IstaLoginInfo";
import MoodBlock from "../ui/MoodBlock";
import NiaButton from "../ui/NiaButton";

// add props:
export type AnonymousIndexScreenProps = {
  // add properties
  loginOnly?: boolean;
};

export const AnonymousIndexScreen = (props: AnonymousIndexScreenProps) => {
  const ui = useContext(UiContext);

  const { t } = useTranslation("ucet");

  const isLoginWindow = useMemo(() => {
    const u = new URL(window.location.href);
    const r = u.searchParams.get("returnTo")?.trim();
    const c = u.searchParams.get("clientId")?.trim();
    return r && c;
  }, []);

  const fromIsrb = useMemo(() => {
    // there is client_id=isrb2 in query string, and oauth=true
    return (
      ucetAppConfig.oAuthClientId === "isrb2" && ucetAppConfig.oAuth === "true"
    );
  }, []);

  const fromIsta = useMemo(() => {
    // there is client_id=isrb2 in query string, and oauth=true
    return (
      ucetAppConfig.oAuthClientId === "ista" && ucetAppConfig.oAuth === "true"
    );
  }, []);

  const changePassword = () => {
    ui.openDialog("forgottenPassword");
  };

  const hideBanner = isLoginWindow || fromIsta || props.loginOnly;

  const LoginComponent = useCallback(() => {
    return (
      <Stack
        direction="column"
        spacing={2}
        paddingRight={{
          xs: 0,
          lg: 8,
        }}
      >
        <Typography variant={"h4"} marginBottom={4}>
          {t("ucet.home.login.title", "Vyberte způsob přihlášení")}
        </Typography>

        {featureNiaLogin && (
          <NiaButton>
            <HomeButton
              left={
                <img
                  alt="Czech lion"
                  src={
                    process.env.REACT_APP_BASEPATH + "/images/czech-lion.png"
                  }
                  style={{ width: "34px" }}
                />
              }
              main={t("ucet.home.button.loginNia", "Identita občana")}
              subtitle={t(
                "ucet.home.button.loginNiaDescription",
                "Využijte Bankovní identitu, MojeID ..."
              )}
            />
          </NiaButton>
        )}

        <Button
          variant={"text"}
          onClick={() => ui.openDialog("login")}
          sx={{
            ":hover": {
              backgroundColor: "#F1F8FF",
              textDecoration: "none",
            },
          }}
        >
          <HomeButton
            left={<MailOutlineIcon fontSize={"large"} />}
            main={t("ucet.home.button.loginEmail", "E-mail")}
          />
        </Button>

        <GoogleButton>
          <HomeButton
            left={
              <img
                alt="Login icon"
                src={
                  process.env.REACT_APP_BASEPATH +
                  "/images/google-login-icon.png"
                }
                style={{
                  width: "34px",
                  transform: "translateX(2px)",
                }}
              />
            }
            main={t("ucet.home.button.loginGoogle", "Google")}
          />
        </GoogleButton>
      </Stack>
    );
  }, [t, ui]);

  if (props.loginOnly) {
    return (
      <Center sx={{ pt: 4 }}>
        <SistaCard sx={{ maxWidth: "40em", p: 2, margin: "0 auto" }}>
          <Center>
            <LoginComponent />
          </Center>
        </SistaCard>
      </Center>
    );
  }

  return (
    <>
      <Grid
        container
        columnSpacing={8}
        rowSpacing={2}
        sx={hideBanner ? { paddingTop: 2 } : { paddingBottom: 2 }}
      >
        {!hideBanner && (
          <Grid item xs={12} md={6}>
            <Box padding={{ xs: 2, md: 8 }}>
              <Typography variant={"h1"} marginBottom={4}>
                {t("ucet.home.title", "Získejte prostředky pro váš projekt")}
              </Typography>
              <Typography variant={"body1"}>
                <Trans i18nKey={"ucet.home.about"}>
                  Přihlašte se do informačního systému SISTA, mějte přístup ke
                  všem aktuálním možnostem podpory, komunikujte s TA ČR a
                  dalšími poskytovateli podpory a spravujte své projekty v
                  realizaci. Zapojit se můžete i do hodnocení výzkumných
                  projektů.
                </Trans>
              </Typography>
            </Box>
          </Grid>
        )}
        {!hideBanner && (
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: { md: "block", xs: "none" } }}
          >
            <MoodBlock />
          </Grid>
        )}

        {/*{!isLoginWindow && fromIsrb && (*/}
        {/*  <IsrbLoginInfo onClick={changePassword} />*/}
        {/*)}*/}
        {!isLoginWindow && fromIsta && (
          <IstaLoginInfo onClick={changePassword} />
        )}

        <Grid item xs={12}>
          <SistaCard>
            <Grid item container xs={12}>
              <Grid item xs={12} md={6} paddingX={6} paddingY={4}>
                <LoginComponent />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                paddingX={8}
                paddingY={4}
                borderLeft={1}
                borderColor={"divider"}
              >
                <Stack direction="column">
                  <SistaFormHeader
                    title={t("ucet.home.register.title", "Jste tu poprvé?")}
                    subtitle={t(
                      "ucet.home.register.description",
                      "Než začnete se systémem pracovat, je nutné se registrovat."
                    )}
                  />
                  <Box display={"flex"} justifyContent={"flex-end"}>
                    <Button
                      variant={"contained"}
                      component={Link}
                      to={"/register"}
                    >
                      {t("ucet.button.register", "Registrovat se")}
                    </Button>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </SistaCard>
        </Grid>
      </Grid>
      <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/d4f3df74-7840-47ba-ae2a-7ef3ac3d35ea/cd.js"
        type="text/javascript"
        async
      ></script>
      <Box
        sx={{
          textAlign: "center",
          borderTop: 1,
          borderTopColor: theme.palette.divider,
          p: 2,
          mt: 4,
        }}
      >
        <Typography variant={"body2"}>
          Copyright © 2024 - TA ČR &nbsp; &middot; &nbsp;
          <a
            href="https://www.tacr.cz/prohlaseni-o-pristupnosti/"
            target="_blank"
            rel="noreferrer"
          >
            {t("ucet.home.footer.accessibility", "Prohlášení o přístupnosti")}
          </a>
          &nbsp; &middot; &nbsp;
          <a
            href="https://www.tacr.cz/o-nas/gdpr-v-ta-cr/"
            target="_blank"
            rel="noreferrer"
          >
            {t(
              "ucet.home.footer.privacyPolicy",
              "Zásady zpracování osobních údajů"
            )}
          </a>
        </Typography>
      </Box>
    </>
  );
};

type HomeButtonProps = {
  left: React.ReactNode;
  main: string;
  subtitle?: string;
};

export const HomeButton = (props: HomeButtonProps) => {
  return (
    <Stack
      direction={"row"}
      spacing={2}
      width={"100%"}
      justifyContent={"flex-start"}
      style={{ textDecoration: "none", color: "black" }}
    >
      <Box sx={{ flexGrow: 0 }}>{props.left}</Box>
      <Box sx={{ flexGrow: 1, textAlign: "left" }}>
        <Typography variant={"body1"}>{props.main}</Typography>
        {props.subtitle && (
          <Typography variant={"body2"}>{props.subtitle}</Typography>
        )}
      </Box>
      <Box>
        <NavigateNextIcon fontSize="large" />
      </Box>
    </Stack>
  );
};
