import {
  FormButton,
  Profile,
  Right,
  SistaFormHeader,
  useEmailValidation,
  useMaxLengthValidation,
  useMinLengthValidation,
  usePhoneNumberValidation,
  useRequiredValidation,
} from "@sista/library-stasi";
import { useFormContext } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

export type ProfileContactFormProps = {
  saveButtonLabel?: string | undefined;
};

export const ProfileContactForm = (props: ProfileContactFormProps) => {
  const form = useFormContext<Profile>();

  const { t } = useTranslation("ucet");

  const required = useRequiredValidation();
  const maxLength = useMaxLengthValidation(129);
  const minLength = useMinLengthValidation(6);
  const phone = usePhoneNumberValidation();
  const email = useEmailValidation();

  return (
    <>
      {
        <SistaFormHeader
          title={t("common.contact.headline", "Kontaktní údaje")}
          subtitle={null}
        />
      }

      <TextFieldElement
        name={"email"}
        label={t("common.email", "Email")}
        validation={{ ...required, ...minLength, ...maxLength, ...email }}
      />

      <TextFieldElement
        name={"phone"}
        label={t("common.phone", "Telefon")}
        validation={{ ...minLength, ...maxLength, ...phone }}
      />

      <Right sx={{ marginTop: 4, marginBottom: 4 }}>
        <FormButton variant={"contained"} type="submit">
          {t("common.save", "Uložit")}
        </FormButton>
      </Right>
    </>
  );
};
