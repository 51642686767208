import { Box, Stack, Typography } from "@mui/material";
import { AlertsContext } from "@sista/dista-ui";
import {
  Organization,
  SmartButton,
  UiContext,
  usePager,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";

export type OrganizationRequestToJoinDialogProps = {
  organization: Organization;
  onNewIco: () => void;
};

export const OrganizationRequestToJoinDialog = (
  props: OrganizationRequestToJoinDialogProps
) => {
  const pager = usePager(0);
  const { t } = useTranslation("ucet");
  const { showInfo } = useContext(AlertsContext);
  const rej = useRejstrikClient();
  const navigate = useNavigate();
  const { closeDialogs } = useContext(UiContext);

  const [joinToOrganizationCall, joinToOrganizationState] = useSafeSistaAsync(
    rej.joinToOrganization
  );

  const joinToOrganizationHandler = useCallback(async () => {
    const result = await joinToOrganizationCall({
      organizationId: props.organization?.id,
    });
    if (typeof result === "boolean" && result) {
      closeDialogs();
      showInfo(
        t(
          "ucet.requestToJoin.requestSent",
          "Žádost o přidání k organizaci byla odeslána správci organizace. Tato operace může trvat několik dní."
        )
      );
    }
  }, [
    joinToOrganizationCall,
    props.organization?.id,
    t,
    showInfo,
    closeDialogs,
  ]);

  return (
    <>
      {pager.page === 0 && (
        <Stack direction={"column"} spacing={2}>
          <Typography variant={"body1"} sx={{ mt: 2 }}>
            {t("ucet.requestToJoin.description.part1", "Společnost ")}
            {props.organization?.displayName}
            {t("ucet.requestToJoin.description.part2", " s IČ ")}
            {props.organization?.idNumber}
            {t(
              "ucet.requestToJoin.description.part3",
              " již správce má. Chcete požádat o přidání?"
            )}
          </Typography>
          <Stack direction={"row"} spacing={2}>
            <SmartButton
              onClick={() => {
                props.onNewIco();
              }}
              variant={"text"}
            >
              {t("ucet.requestToJoin.no", "Vybrat jiné IČ")}
            </SmartButton>
            <Box sx={{ flexGrow: 1 }} />
            <SmartButton
              onClick={() => {
                pager.nextPage();
              }}
              variant={"contained"}
            >
              {t("ucet.requestToJoin.yes", "Ano")}
            </SmartButton>
          </Stack>
        </Stack>
      )}
      {pager.page === 1 && (
        <Stack direction={"column"} spacing={2}>
          <Typography variant={"h6"} sx={{ mt: 2 }}>
            {t(
              "ucet.requestToJoin.title",
              "Požádat správce o přidání k organizaci"
            )}
          </Typography>
          <Typography variant={"body1"}>
            {t(
              "ucet.requestToJoin.requestDescription",
              "Nyní odešlete žádost o přiřazení k vámi vybrané organizaci správci, který Vaši žádost schválí, případně může zamítnout."
            )}
          </Typography>
          <Stack direction={"row"} spacing={2}>
            <Box sx={{ flexGrow: 1 }} />
            <SmartButton
              state={joinToOrganizationState}
              onClick={() => {
                joinToOrganizationHandler();
              }}
              variant={"contained"}
            >
              {t("ucet.requestToJoin.request", "Požádat")}
            </SmartButton>
          </Stack>
        </Stack>
      )}
    </>
  );
};
