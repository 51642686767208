import { Card, CardContent, Typography } from "@mui/material";
import { IdmClientContext, Profile } from "@sista/idm-client";
import { logi, SistaDialog, SistaFormHeader } from "@sista/library-stasi";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { nvl } from "../../../utils/nvl";
import { useProfileNames } from "../../../utils/useProfileNames";

type ChooseProfileAfterLoginProps = {
  profiles: Profile[];
};

export const ChooseProfileAfterLogin = (
  props: ChooseProfileAfterLoginProps
) => {
  const { loggedAccount, roles, profileChosenByUser, switchProfile, logout } =
    useContext(IdmClientContext);
  const { t } = useTranslation("ucet");
  const profileName = useProfileNames();

  // const onSubmit = useCallback(async () => { return Promise.resolve("A") }, [depen],);
  // const onClick = useCallback((a: number) => {  return a.toString(); }, [depen],);
  // const val = useMemo(() => { return "AAA";}, [depen]);

  const choose = useCallback(
    (p: Profile) => {
      switchProfile(p.id, true);
    },
    [switchProfile]
  );

  logi("ChooseProfileAfterLogin", props.profiles);

  useEffect(() => {
    // autochoose
  }, [choose]);

  return (
    <SistaDialog
      title={t("ucet.profiles.pleaseChooseTitle", "Volba profilu")}
      onClose={logout}
    >
      <SistaFormHeader
        title={t("ucet.profiles.pleaseChooseHeadline", "Vyberte prosím profil")}
        subtitle={t(
          "ucet.profiles.pleaseChooseDescription",
          "V SISTA máte více profilů, vyberte prosím, který chcete pro přihlášení použít."
        )}
      />

      {props.profiles.map((p) => (
        <Card key={p.id} sx={{ marginBottom: 1 }}>
          <CardContent onClick={() => choose(p)}>
            <Typography variant="h3">{profileName(p.type)}</Typography>
            <Typography variant="body1">
              {t("ucet.settings.contactEmail", "e-mail:")}
              {nvl(p.email)},&nbsp;
              {t("ucet.settings.contactPhone", "tel:")}
              {nvl(p.phone)}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </SistaDialog>
  );
};
