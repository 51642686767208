import { Box } from "@mui/system";
import { AlertsContext, DistaContent } from "@sista/dista-ui";
import { SistaPageLayout } from "@sista/fe-commons";
import { IdmClientContext } from "@sista/idm-client";
import { SistaFormProviderWithDefaults } from "@sista/service-forms";
import { SistaSpravaUkoluList } from "@sista/service-workflow-ui";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useLocalNavigation } from "../../hooks/useLocalNavigation";

export const AdHocTaskScreen = () => {
  const { t } = useTranslation("ucet");

  const { loggedAccount } = useContext(IdmClientContext);

  const { showSuccess } = useContext(AlertsContext);

  const contextStr = useParams().context;

  const context = useMemo(() => {
    return contextStr ? contextStr.split(",") : [];
  }, [contextStr]);

  const navigate = useNavigate();

  const headerConfig = useMemo(() => {
    return {
      breadcrumbs: [
        {
          title: t("ucet.breadcrumbs.home", "Přehled"),
          action: () => navigate("/"),
        },
        {
          title: t("ucet.breadcrumbs.adHocTask", "Úkol"),
        },
      ],
      pageTitle: t("ucet.adHocTask.title", "SISTA úkol"),
    };
  }, [navigate, t]);

  const localNavigationConfig = useLocalNavigation();

  const success = useCallback(() => {
    showSuccess(t("ucet.adHocTask.success", "Úkol byl úspěšně splněn"));
    navigate("/");
  }, [navigate, showSuccess, t]);

  return (
    <SistaPageLayout
      localNavigationConfig={localNavigationConfig}
      searchProps={{
        entityTypes: ["unresolvedTasks"],
      }}
    >
      <DistaContent headerConfig={headerConfig} fullWidth={false}>
        <SistaFormProviderWithDefaults>
          <Box sx={{ width: "40em" }}>
            <SistaSpravaUkoluList
              variant={"detailed"}
              loggedAuthKey={loggedAccount.authKey}
              spravaUkoluRoot={"/sprava-ukolu"}
              tasksContext={context}
              onSuccess={success}
            />
          </Box>
        </SistaFormProviderWithDefaults>
      </DistaContent>
    </SistaPageLayout>
  );
};
