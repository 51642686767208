import { Stack } from "@mui/material";
import { AlertsContext } from "@sista/dista-ui";
import { IdmClientContext } from "@sista/idm-client";
import {
  FileUploadRequest,
  FileV2DTO,
  SistaFistoProgress,
  SistaFistoSingle,
  SistaFistoUpload,
} from "@sista/service-fisto-ui";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

type TempFilesElementProps = {
  multiple?: boolean;
  name: string;
  context: string[];
  label?: string;
};

export const TempFilesElement = (props: TempFilesElementProps) => {
  const { loggedAccount } = useContext(IdmClientContext);

  const { showError, showSuccess } = useContext(AlertsContext);

  const form = useFormContext();

  const { t } = useTranslation("ucet");

  const [inProgress, setInProgress] = useState<FileUploadRequest[]>([]);
  const [uploaded, setUploaded] = useState<string[]>([]);

  const addFileInProgress = useCallback(
    (fileUploadRequest: FileUploadRequest) => {
      setInProgress((prev) => [...prev, fileUploadRequest]);
    },
    []
  );

  const fileDeleted = (f: FileV2DTO) => {
    setUploaded((prevState) => {
      return prevState.filter((e) => e != f.fileId!);
    });
  };

  const myOnFileUploaded = useCallback(
    (request: FileUploadRequest, file: FileV2DTO) => {
      showSuccess(t("fisto.view.fileUploaded", "Soubor byl úspěšně nahrán."));
      setUploaded((prevState) => {
        if (props.multiple == false) return [file.fileId!];
        return [...prevState, file.fileId!];
      });
      setInProgress((prev) =>
        prev.filter((x) => x.tempRequestId !== request.tempRequestId)
      );
    },
    [props.multiple, showSuccess, t]
  );

  const myOnFileUploadFailed = useCallback(
    (request: FileUploadRequest, error: string) => {
      showError(t("fisto.view.fileUploadEnd", "Nahrávaní souboru selhalo."));
      setInProgress((prev) =>
        prev.filter((x) => x.tempRequestId !== request.tempRequestId)
      );
    },
    [showError, t]
  );

  useEffect(() => {
    if (props.multiple == false) {
      if (uploaded == null || uploaded.length == 0) {
        form.setValue(props.name, null);
      } else {
        form.setValue(props.name, uploaded[0]);
      }
    } else {
      form.setValue(props.name, uploaded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.setValue, props.name, uploaded, props.multiple]);

  return (
    <>
      {uploaded.length > 0 &&
        uploaded.map((fileId) => (
          <SistaFistoSingle
            key={fileId}
            fileId={fileId}
            authKey={loggedAccount.authKey}
            onFileDeleted={fileDeleted}
          />
        ))}
      <Stack direction={"row"} spacing={2} sx={{ my: 2 }}>
        <Stack spacing={2}>
          {inProgress.length > 0 &&
            inProgress.map((f) => {
              return (
                <SistaFistoProgress
                  key={f.tempRequestId}
                  authKey={loggedAccount.authKey}
                  onFileUploaded={myOnFileUploaded}
                  context={props.context}
                  category="tempCategory"
                  onFileUploadFailed={myOnFileUploadFailed}
                  fileUploadRequest={f}
                />
              );
            })}
        </Stack>
        <div style={{ flexGrow: 1 }}></div>
        <SistaFistoUpload
          authKey={loggedAccount.authKey}
          category="tempCategory"
          uploadButtonLabel={props.label}
          context={props.context}
          onFileUploadRequested={addFileInProgress}
          multiple={props.multiple == true}
        />
      </Stack>
    </>
  );
};
