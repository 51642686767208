import {
  ChangePasswordDTO,
  FormButton,
  Right,
  SistaFormHeader,
  useRequiredValidation,
} from "@sista/library-stasi";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { SistaPasswordInput } from "../../ui/SistaPasswordInput";

export const PersonChangePasswordForm = () => {
  const form = useFormContext<ChangePasswordDTO>();

  const { t } = useTranslation("ucet");

  const required = useRequiredValidation();
  //const password = usePasswordValidation();

  return (
    <>
      <SistaFormHeader
        title={t("ucet.person.changePassword.title", "Změna hesla")}
        subtitle={null}
      />

      <SistaPasswordInput
        newPassword={false}
        name="oldPassword"
        label={t("ucet.form.oldPassword", "Vaše stávající heslo")}
      />

      <SistaPasswordInput
        newPassword={true}
        name="newPassword"
        label={t("ucet.form.newPassword", "Nové heslo")}
      />

      <Right sx={{ marginTop: 4, marginBottom: 4 }}>
        <FormButton variant={"contained"} type="submit">
          {t("common.save", "Uložit")}
        </FormButton>
      </Right>
    </>
  );
};
