import { Stack, Typography } from "@mui/material";
import {
  isTrue,
  Right,
  SistaFormHeader,
  SmartButton,
  useEmailValidation,
  useMaxLengthValidation,
  useMinLengthValidation,
  useRequiredValidation,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { CreateAccountRequestForm } from "./RegisterAccountDialog";
import { useIdmClient } from "../../../api/utils/useIdmClient";
import {
  featureAlternativeRegistration,
  featureNiaLogin,
} from "../../../utils/features";
import GoogleButton from "../../ui/GoogleButton";
import NiaButton from "../../ui/NiaButton";
import { SistaPasswordInput } from "../../ui/SistaPasswordInput";

export type RegisterAccountStep1Props = {
  initialValues: CreateAccountRequestForm;
  onSubmit: (d: CreateAccountRequestForm) => void;
  onCancel?: () => void;
};

export const RegisterAccountStep1Form = (props: RegisterAccountStep1Props) => {
  const form = useForm<CreateAccountRequestForm, any>({
    defaultValues: props.initialValues,
  });

  const { t } = useTranslation("ucet");
  const required = useRequiredValidation();
  const email = useEmailValidation();
  const idmClient = useIdmClient();
  const maxLength = useMaxLengthValidation(129);
  const minLength = useMinLengthValidation(2);

  const submitStepCallback = useCallback(
    async (d: CreateAccountRequestForm) => {
      const isRegistered = isTrue(
        await idmClient.isEmailRegistered({ value: d.accountDTO.email })
      );
      if (isRegistered) {
        form.setError("accountDTO.email", {
          type: "manual",
          message: t(
            "ucet.error.emailExists",
            "Tento e-mail je již registrován."
          ),
        });
        return d;
      }
      await props.onSubmit(d);
      return d;
    },
    [form, idmClient, props, t]
  );

  const [submitStepCall, submitStepState] =
    useSafeSistaAsync(submitStepCallback);

  return (
    <FormContainer onSuccess={submitStepCall} formContext={form}>
      {/*<BlockLeave/>*/}
      <Stack direction="column" spacing={2}>
        <SistaFormHeader
          title={t("ucet.registration.basicTitle", "Přihlašovací údaje")}
          subtitle={t(
            "ucet.registration.basicSubtitle",
            "Zadejte platný e-mail a heslo nebo se zaregistrujte za použití účtu Google."
          )}
        />

        <TextFieldElement
          name="accountDTO.email"
          autoComplete={"email"}
          label={t("ucet.form.email", "E-mail")}
          validation={{ ...required, ...email, ...maxLength }}
        />

        <SistaPasswordInput newPassword={true} name="accountDTO.password" />

        <Right>
          <SmartButton
            state={submitStepState}
            sx={{ marginTop: 4, marginBottom: 4 }}
          >
            {t("common.continue", "Pokračovat")}
          </SmartButton>
        </Right>
        {featureAlternativeRegistration && (
          <>
            <Typography
              marginBottom={2}
              marginTop={2}
              typography={"body1"}
              align={"center"}
            >
              {t("common.or", "nebo")}
            </Typography>
            <Stack
              direction={"column"}
              justifyContent="center"
              alignItems="stretch"
              spacing={1}
            >
              <GoogleButton />
              {featureNiaLogin && <NiaButton />}
            </Stack>
          </>
        )}
      </Stack>
    </FormContainer>
  );
};
