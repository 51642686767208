import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import EditIcon from "@mui/icons-material/Edit";
import { Avatar, Box, Button, Chip, Stack, Tooltip } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { AlertsContext } from "@sista/dista-ui";
import { IdmClientContext, Relation } from "@sista/idm-client";
import { Right, UiContext, useSafeSistaAsync } from "@sista/library-stasi";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";
import { ConfigContext } from "../../../hooks/ConfigContext";
import { OrganizationEditProfileDialog } from "../../dialogs/organization/OrganizationEditProfileDialog";
import { OrganizationInviteDialog } from "../../dialogs/organization/OrganizationInviteDialog";
import { OrganizationProjectRolesDialog } from "../../dialogs/organization/OrganizationProjectRolesDialog";
import { OrganizationRolesDialog } from "../../dialogs/organization/OrganizationRolesDialog";

type Filter = "all" | "active" | "blocked" | "invited";
const allFilter = ["all", "active", "blocked", "invited"] as Filter[];

export const OrganizationUsersContent = () => {
  const { openDialog, openedDialog, openedDialogData } = useContext(UiContext);
  const idm = useContext(IdmClientContext);
  const { filterProfileRoles, roleName } = useContext(ConfigContext);
  const alerts = useContext(AlertsContext);
  const rejstrikClient = useRejstrikClient();
  const { t, i18n } = useTranslation("ucet");
  const { organizationId } = useParams();
  const [filter, setFilter] = useState("all" as Filter);
  const organizationGenerichKey = organizationId.replace(":", ".");
  const organizationGenerichCode = organizationGenerichKey.split(".")[1];
  const lang = i18n.language;

  const [teamCall, teamState] = useSafeSistaAsync(
    rejstrikClient.getOrganizationTeam
  );

  const [getPersonProfileCall, getPersonProfileState] = useSafeSistaAsync(
    rejstrikClient.getPersonProfileInOrganization
  );

  const filterLabels = {
    all: t("ucet.organization.filter.all", "Všichni"),
    active: t("ucet.organization.filter.active", "Aktivní"),
    blocked: t("ucet.organization.filter.blocked", "Zablokovaní"),
    invited: t("ucet.organization.filter.invited", "Pozvaní"),
  };

  const openInviteDialog = useCallback(
    () =>
      openDialog("organizationInvitePerson", {
        organizationId: organizationId,
      }),
    [openDialog, organizationId]
  );

  const refresh = useCallback(async () => {
    // wait 200ms, nez to probubla platformou sem a tam
    console.log("waiting");
    await new Promise((r) => setTimeout(r, 300));
    console.log("refreshing");
    // then refresh
    if (organizationId && idm.roles) {
      teamCall({
        organizationId: organizationId,
        pageSize: 1000,
      });
    }
  }, [organizationId, idm.roles, teamCall]);

  function dummy(str: string): any {
    return null;
  }

  const rolesRenderer = useCallback(
    (params: GridRenderCellParams) => {
      const rs = params.row._viewData.relations as Relation[];

      return (
        <Tooltip
          title={rs
            .filter((r) => r.refEntityId == organizationId)
            .map((r) => roleName(r.role, lang))
            .join(", ")}
        >
          <span>
            {rs
              .filter((r) => r.refEntityId == organizationId && r.to == null)
              .map((r) => (
                <span key={r.id}>
                  <Chip label={roleName(r.role, lang)} sx={{ mr: 1 }} />{" "}
                </span>
              ))}
          </span>
        </Tooltip>
      );
    },
    [lang, roleName, organizationId]
  );

  // screen starts here
  useEffect(() => {
    refresh();
  }, [organizationId, refresh]);

  const rows = useMemo(() => {
    const r = teamState.value ?? [];
    /* if (filter === "active") {
      r = r.filter((p) => p.dateDisabled == null && p.lastSeen != null);
    } else if (filter === "blocked") {
      r = r.filter((p) => p.dateDisabled != null);
    } else if (filter === "invited") {
      r = r.filter((p) => p.dateDisabled == null && p.lastSeen == null);
    } else {
      // all
    } */
    return r;
  }, [teamState.value]);

  const columns = useMemo(() => {
    return [
      {
        field: "picture",
        headerName: "",
        sortable: false,
        width: 48,
        renderCell: (params: GridRenderCellParams) => {
          const p = params.row;
          return (
            <Avatar
              src={p.profilePic}
              alt={p.displayName}
              sx={{ width: 32, height: 32 }}
            />
          );
        },
      },
      {
        field: "titlesBefore",
        headerName: t("ucet.organization.table.titlesBefore", "Tituly před"),
        sortable: true,
        flex: 1,
        width: 100,
      },
      {
        field: "firstName",
        headerName: t("ucet.organization.table.firstName", "Jméno"),
        sortable: true,
        flex: 1,
        width: 100,
      },
      {
        field: "lastName",
        headerName: t("ucet.organization.table.lastName", "Příjmení"),
        sortable: true,
        flex: 1,
        width: 120,
      },
      {
        field: "titlesAfter",
        headerName: t("ucet.organization.table.titlesAfter", "Tituly za"),
        sortable: true,
        flex: 1,
        width: 100,
      },
      {
        field: "email",
        headerName: t("ucet.organization.table.email", "E-mail"),
        sortable: true,
        flex: 1,
        width: 100,
      },
      {
        field: "phone",
        headerName: t("ucet.organization.table.phone", "Telefon"),
        sortable: true,
        flex: 1,
        width: 100,
      },
      {
        field: "roles",
        headerName: t("ucet.organization.table.role", "Role / Zapojení"),
        sortable: false,
        renderCell: rolesRenderer,
        width: 450,
      },
      /* {
        field: "section",
        headerName: t("ucet.organization.table.section", "Útvar"),
        valueGetter: (params: GridValueGetterParams) => {
          const d =
            params.row._viewData?.d[organizationGenerichCode]?.details ?? "";
          return [d?.section, d?.info].filter(Boolean).join(" / ");
        },
        sortable: true,
        flex: 2,
        width: 200,
      }, */
      /* {
        field: "stats",
        headerName: t("ucet.organization.table.status", "Stav"),
        sortable: false,
        renderCell: profileStateRenderer,
        width: 150,
      }, */
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params: any) =>
          [
            params.row.lastSeen == null && (
              <GridActionsCellItem
                key={"edit"}
                icon={<EditIcon />}
                label={t("ucet.organization.table.editAction", "Upravit")}
                disabled={getPersonProfileState.pending}
                onClick={async () => {
                  const result = await getPersonProfileCall({
                    organizationId: organizationId,
                    profileId: params.row.id,
                  });
                  if (typeof result === "object" && result) {
                    openDialog("organizationEditProfile", params.row);
                  }
                }}
              />
            ),
            params.row.dateDisabled == null && (
              <GridActionsCellItem
                key={"roles"}
                icon={<AdminPanelSettingsIcon />}
                label={t(
                  "ucet.organization.table.rolesAction",
                  "Nastavení rolí uživatele"
                )}
                onClick={() => openDialog("organizationEditRoles", params.row)}
                showInMenu
              />
            ),
            <GridActionsCellItem
              key={"roles"}
              icon={<AdminPanelSettingsIcon />}
              label={t(
                "ucet.organization.table.projectRolesAction",
                "Přehled zapojení uživatele"
              )}
              onClick={() =>
                openDialog("organizationViewProjectRoles", params.row)
              }
              showInMenu
            />,
            // params.row.lastSeen == null && params.row.dateDisabled == null && (
            //   <GridActionsCellItem
            //     key={"resend"}
            //     icon={<ReplayIcon />}
            //     label={t(
            //       "ucet.organization.table.resendAction",
            //       "Znovu poslat pozvánku"
            //     )}
            //     onClick={dummy(params.row.id!)}
            //     showInMenu
            //   />
            // ),
            // params.row.lastSeen != null && (
            //   <GridActionsCellItem
            //     key={"transfer"}
            //     icon={<SwapHorizIcon />}
            //     label={t(
            //       "ucet.organization.table.transferAction",
            //       "Předat projekty jinému uživateli"
            //     )}
            //     onClick={dummy(params.row.id!)}
            //     showInMenu
            //   />
            // ),
          ].filter(Boolean),
      },
    ];
  }, [
    t,
    rolesRenderer,
    openDialog,
    getPersonProfileCall,
    organizationId,
    getPersonProfileState.pending,
  ]);

  return (
    <>
      <Stack direction={"column"} spacing={2}>
        <Right sx={{ my: 2 }}>
          <Button variant="contained" onClick={() => openInviteDialog()}>
            {t("ucet.organization.users.buttonInvite", "Pozvat uživatele")}
          </Button>
        </Right>
        {/* <Stack
          direction={"row"}
          sx={{ backgroundColor: "secondary.light", p: 1 }}
        >
          <FilterListIcon sx={{ mr: 2 }} fontSize={"medium"} />
          {allFilter.map((f) => {
            return (
              <Chip
                color={f === filter ? "primary" : "default"}
                key={f}
                label={filterLabels[f]}
                onClick={() => setFilter(f)}
                sx={{ mr: 1 }}
              />
            );
          })}
        </Stack> */}

        <Box sx={{ flexGrow: 1 }}>
          <DataGrid columns={columns} pageSizeOptions={[100]} rows={rows} />
        </Box>
        {/* <TeamStats team={teamState.value} /> */}
      </Stack>
      {openedDialog == "organizationInvitePerson" && (
        <OrganizationInviteDialog
          organizationId={organizationId}
          onDone={refresh}
        />
      )}
      {openedDialog == "organizationEditRoles" && (
        <OrganizationRolesDialog
          organizationId={organizationId}
          profile={openedDialogData}
          onDone={refresh}
        />
      )}
      {openedDialog == "organizationViewProjectRoles" && (
        <OrganizationProjectRolesDialog
          profile={openedDialogData}
          onDone={refresh}
        />
      )}
      {getPersonProfileState.value &&
        openedDialog == "organizationEditProfile" && (
          <OrganizationEditProfileDialog
            organizationId={organizationId}
            profile={openedDialogData}
            additionalData={getPersonProfileState.value}
            onDone={refresh}
          />
        )}
    </>
  );
};
