export const filterInterrestingContexts = (contexts: string[]) => {
  const i = contexts.filter(
    (context) =>
      context != null &&
      (context.startsWith("urn:sista:need:") ||
        context.startsWith("urn:sista:projects:") ||
        context.startsWith("urn:sista:profiles:") ||
        context.startsWith("urn:sista:organizations:") ||
        context.startsWith("urn:sista:expertOrders:"))
  );
  i.sort();
  return i;
};
