import { Container } from "@mui/system";
import { LandingPageLayout, LogoProps } from "@sista/dista-ui";
import { useAnonymousUserTopBar } from "@sista/fe-commons";
import {
  ErrorScreen,
  logi,
  RedirectHome,
  UiContext,
} from "@sista/library-stasi";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useNavigate } from "react-router-dom";

import { ForgottenPasswordDialog } from "../dialogs/login/ForgottenPasswordDialog";
import { LoginAccountDialog } from "../dialogs/login/LoginAccountDialog";
import { OnboardingPasswordDialog } from "../dialogs/login/OnboardingPasswordDialog";
import { RegisterAccountDialog } from "../dialogs/registration/RegisterAccountDialog";
import { AnonymousIndexScreen } from "../screens/AnonymousIndexScreen";
import { RedirectRouterScreen } from "../screens/RedirectRouterScreen";
import { RegisterAccountScreen } from "../screens/RegisterAccountScreen";
import { GoogleScript } from "../ui/GoogleButton";

export const AnonymousContent: React.FC = () => {
  const ui = useContext(UiContext);
  const { t } = useTranslation("ucet");
  const navigate = useNavigate();

  const topMenu = useAnonymousUserTopBar({
    //showLanguageSelect: true,
    showLogin: false,
  });

  const logoProps = useMemo(() => {
    return {
      onLogoClick: () => navigate("/"),
    } as LogoProps;
  }, [navigate]);

  const niaError = t(
    "ucet.error.missingNia",
    "Identita občana nám neposkytla všechny údaje, které potřebujeme k založení účtu SISTA, buď je nemá k dispozici, nebo jste jí k tomu nedal(a) potřebný souhlas. Můžete to zkusit znovu."
  );

  logi("AnonymousContent, dialog= ", ui.openedDialog);

  return (
    // Tady nelze pouzit SistaPageLayout, v prihlasovacim okne potrebujeme zvlastni chovani
    <LandingPageLayout topMenuProps={topMenu} logoProps={logoProps}>
      <Container maxWidth="lg" sx={{ my: 0, mx: "auto" }}>
        <Routes>
          <Route path="/" element={<AnonymousIndexScreen />} />
          <Route path="/redirect/:target" element={<RedirectRouterScreen />} />
          <Route path="/register" element={<RegisterAccountScreen />} />
          <Route path="/onboarding" element={<OnboardingPasswordDialog />} />
          <Route
            path="/ukol/:context"
            element={<AnonymousIndexScreen loginOnly={true} />}
          />
          <Route
            path={"/sprava-organizace/*"}
            element={<AnonymousIndexScreen loginOnly={true} />}
          />
          <Route
            path={"/sprava-resortu/*"}
            element={<AnonymousIndexScreen loginOnly={true} />}
          />
          <Route
            path="/missing-nia-fields"
            element={<ErrorScreen error={niaError} />}
          />
          <Route path={"*"} element={<RedirectHome />} />
        </Routes>
        <GoogleScript />
      </Container>

      {ui.openedDialog === "registration" && <RegisterAccountDialog />}
      {ui.openedDialog === "login" && <LoginAccountDialog />}
      {ui.openedDialog === "forgottenPassword" && <ForgottenPasswordDialog />}
    </LandingPageLayout>
  );
};
