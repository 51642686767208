import {IdmClientContext} from "@sista/idm-client";
import {buildSpravaUkoluClient} from "@sista/library-stasi";
import {useContext, useMemo} from "react";

export const useSpravaUkoluClient = () => {
  const c = useContext(IdmClientContext);
  if (c == null) {
    throw new Error("IdmClientContext is null");
  }
  return useMemo(
    () =>
      buildSpravaUkoluClient(
        process.env.REACT_APP_SPRAVA_UKOLU_ROOT,
        c?.loggedAccount?.authKey
      ),
    [c?.loggedAccount]
  );
};
