import { ReactNode } from "react";

export function detailItem(l: string, r?: ReactNode) {
  return {
    primaryColumn: l,
    secondaryColumn: r ?? "-",
  };
}

export function noData() {
  return detailItem("-", "");
}
