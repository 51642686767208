import { Button, Grid, Stack, Typography } from "@mui/material";
import { AlertsContext } from "@sista/dista-ui";
import { ucetAppConfig } from "@sista/idm-client";
import {
  ProfileType,
  Right,
  SistaCard,
  isDev,
  isProd,
} from "@sista/library-stasi";
import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";

type IntentPickerProps = {
  onIntentSelected: (intent: ProfileType) => void;
};

export const IntentPicker = (props: IntentPickerProps) => {
  const a = useContext(AlertsContext);

  const { t } = useTranslation("ucet");

  function notPossible() {
    a.showError("Not possible: This feature is not yet implemented");
  }

  const clientId = ucetAppConfig.oAuthClientId;

  const isoAuth = ucetAppConfig.oAuth === "true";

  const isIsrb = isoAuth && clientId === "isrb2";

  return (
    <>
      <Grid container spacing={2} alignItems={"stretch"} sx={{ my: 1 }}>
        <Grid item xs={12} md={6}>
          <SistaCard
            sx={{
              p: 4,
              minHeight: "100%",
              position: "relative",
              paddingBottom: 10,
            }}
          >
            <Stack direction="column" spacing={2}>
              <Typography variant={"h3"} marginBottom={4}>
                {t("ucet.intent.applicant.title", "Uchazeč/Příjemce")}
              </Typography>

              <Typography variant={"body1"} marginBottom={4}>
                {t(
                  "ucet.intent.applicant.subtitle",
                  "Registrujte se jako uchazeč/příjemce a získejte prostředky pro Váš projekt."
                )}
              </Typography>

              <Trans i18nKey={"ucet.intent.applicant.description"}>
                <Typography
                  variant={"body1"}
                  marginBottom={4}
                  component={"div"}
                >
                  <ul>
                    <li>
                      Podávejte návrhy projektů do veřejných soutěží a
                      mezinárodních výzev.
                    </li>
                    <li>Podávejte nabídky do veřejných zakázek.</li>
                    <li>Spravujte Vaše realizované projekty.</li>
                    <li>Komunikujte s poskytovateli on-line.</li>
                  </ul>
                </Typography>
              </Trans>

              <Typography variant={"body2"}>
                {t(
                  "ucet.intent.applicant.possibility",
                  "V budoucnosti se můžete kdykoliv registrovat i jako expert."
                )}
              </Typography>
            </Stack>
            <Right
              sx={{
                position: "absolute",
                right: 0,
                bottom: 0,
                paddingBottom: 2,
                paddingRight: 2,
              }}
            >
              <Button
                variant={"contained"}
                onClick={() => props.onIntentSelected("APPLICANT")}
              >
                {t(
                  "ucet.intent.applicant.button",
                  "Registrovat se jako uchazeč/příjemce"
                )}
              </Button>
            </Right>
          </SistaCard>
        </Grid>
        {!isIsrb && (
          <Grid item xs={12} md={6}>
            <SistaCard
              sx={{
                p: 4,
                minHeight: "100%",
                position: "relative",
                paddingBottom: 10,
              }}
            >
              <Stack direction="column" spacing={2}>
                <Typography variant={"h3"} marginBottom={4}>
                  {t("ucet.intent.expert.title", "Expert")}
                </Typography>

                <Typography variant={"body1"} marginBottom={4}>
                  {t(
                    "ucet.intent.expert.subtitle",
                    "Registujte se jako expert a pomozte nám s hodnocením výzkumných projektů."
                  )}
                </Typography>

                <Trans i18nKey={"ucet.intent.expert.description"}>
                  <Typography
                    variant={"body1"}
                    marginBottom={4}
                    component={"div"}
                  >
                    <ul>
                      <li>Hodnoťte projekty a subjekty.</li>
                      <li>Spolupracujte na veřejných zakázkách.</li>
                      <li>Pracujte za jasně danou finanční odměnu.</li>
                      <li>Komunikujte s TA ČR on-line.</li>
                    </ul>
                  </Typography>
                </Trans>

                <Typography variant={"body2"}>
                  {t(
                    "ucet.intent.expert.possibility",
                    "V rámci registrace se můžete registrovat i jako uchazeč/příjemce."
                  )}
                </Typography>
              </Stack>
              <Right
                sx={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  paddingBottom: 2,
                }}
              >
                {" "}
                <Button
                  variant={"contained"}
                  onClick={() => props.onIntentSelected("EXPERT")}
                  sx={{ mr: 2 }}
                >
                  {t("ucet.intent.expert.button", "Registrovat se jako expert")}
                </Button>
              </Right>
            </SistaCard>
          </Grid>
        )}
      </Grid>
    </>
  );
};
