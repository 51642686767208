import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import { Avatar, Box, Chip, Stack, Tooltip, Typography } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { GridRenderCellParams } from "@mui/x-data-grid/models/params/gridCellParams";
import {
  AlertsContext,
  DistaContent,
  DistaPageHeaderProps,
} from "@sista/dista-ui";
import { SistaPageLayout } from "@sista/fe-commons";
import { IdmClientContext } from "@sista/idm-client";
import { Relation, UiContext, useSafeSistaAsync } from "@sista/library-stasi";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { TeamStats } from "./TeamStats";
import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";
import { ConfigContext } from "../../../hooks/ConfigContext";
import { useLocalNavigation } from "../../../hooks/useLocalNavigation";
import { DepartmentEditDialog } from "../../dialogs/department/DepartmentEditDialog";
import { DepartmentInviteDialog } from "../../dialogs/department/DepartmentInviteDialog";
import { DepartmentProjectRolesDialog } from "../../dialogs/department/DepartmentProjectRolesDialog";
import { DepartmentRolesDialog } from "../../dialogs/department/DepartmentRolesDialog";

type Filter = "all" | "active" | "blocked" | "invited";
const allFilter = ["all", "active", "blocked", "invited"] as Filter[];

export const DepartmentAdminScreen = () => {
  const { openDialog, openedDialog, openedDialogData } = useContext(UiContext);
  const idm = useContext(IdmClientContext);
  const { filterProfileRoles, roleName } = useContext(ConfigContext);
  const alerts = useContext(AlertsContext);
  const rejstrikClient = useRejstrikClient();
  const { t, i18n } = useTranslation("ucet");
  const { departmentId } = useParams();
  const [filter, setFilter] = useState("all" as Filter);
  const departmentGenerichKey = departmentId.replace(":", ".");
  const departmentGenerichCode = departmentGenerichKey.split(".")[1];
  const lang = i18n.language;

  const [teamCall, teamState] = useSafeSistaAsync(
    rejstrikClient.getDepartmentTeam
  );

  const filterLabels = {
    all: t("ucet.department.filter.all", "Všichni"),
    active: t("ucet.department.filter.active", "Aktivní"),
    blocked: t("ucet.department.filter.blocked", "Zablokovaní"),
    invited: t("ucet.department.filter.invited", "Pozvaní"),
  };

  const openInviteDialog = useCallback(
    () => openDialog("departmentInvitePerson"),
    [openDialog]
  );

  const refresh = useCallback(() => {
    if (departmentId && idm.roles) {
      teamCall({
        departmentId: departmentId,
        _viewDataPath: [departmentGenerichKey],
        pageSize: 1000,
      });
    }
  }, [departmentGenerichKey, departmentId, idm.roles, teamCall]);

  function dummy(str: string): any {
    return null;
  }

  // const loadOrganization = useCallback(() => {
  //   rejstrikClient.depa
  // }

  const [deactivateProfileCall] = useSafeSistaAsync(
    useCallback(
      async (id: string) => {
        await rejstrikClient.disableProfileInDepartment({
          profileId: id,
          departmentId: departmentId,
          changeReason: "blocked from z /ucet",
          minorChange: false,
          data: id,
        });
        alerts.showSuccess(
          t("ucet.department.profile.disabledResult", "Profil byl deaktivován")
        );
        refresh();
      },
      [rejstrikClient, departmentId, alerts, t, refresh]
    )
  );

  const [reactivateProfileCall] = useSafeSistaAsync(
    useCallback(
      async (id: string) => {
        await rejstrikClient.enableProfileInDepartment({
          profileId: id,
          departmentId: departmentId,
          changeReason: "Aktivace z UI",
          minorChange: false,
          data: id,
        });
        alerts.showSuccess(
          t("ucet.department.profile.disabledResult", "Profil byl aktivován")
        );
        refresh();
      },
      [rejstrikClient, departmentId, alerts, t, refresh]
    )
  );

  const profileStateRenderer = useCallback(
    (params: GridRenderCellParams) => {
      const p = params.row;
      if (p.dateDisabled != null) {
        return (
          <Typography variant="body1" color="text.disabled">
            {t("ucet.department.profile.statusDisabled", "Zablokován")}
          </Typography>
        );
      }
      if (p.lastSeen == null) {
        return (
          <Typography variant="body1" color="warning.main">
            {t("ucet.department.profile.statusInvited", "Pozvánka poslána")}
          </Typography>
        );
      }
      return (
        <Typography variant="body1" color="primary.main">
          {t("ucet.department.profile.statusActive", "Aktivní")}
        </Typography>
      );
    },
    [t]
  );

  const rolesRenderer = useCallback(
    (params: GridRenderCellParams) => {
      const rs = params.row._viewData.relations as Relation[];

      const hasProject = rs.some((r) => r.refEntityType === "projects");
      return (
        <Tooltip
          title={rs
            .filter((r) => r.refEntityId == departmentId)
            .filter((r) => r.role != "DEPARTMENT_EMPLOYEE")
            .map((r) => roleName(r.role, lang))
            .join(", ")}
        >
          <span>
            {hasProject && (
              <img
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "8px",
                  position: "relative",
                  top: "8px",
                }}
                src="https://fnx-dev.test-sista.tacr.cz/urn-resolver/images/projects.svg"
                alt=""
              />
            )}
            {rs
              .filter((r) => r.refEntityId == departmentId)
              .filter((r) => r.role != "DEPARTMENT_EMPLOYEE")
              .map((r) => (
                <span key={r.id}>
                  <Chip label={roleName(r.role, lang)} sx={{ mr: 1 }} />{" "}
                </span>
              ))}
          </span>
        </Tooltip>
      );
    },
    [lang, roleName, departmentId]
  );

  // screen starts here
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refresh, [departmentId]);

  const rows = useMemo(() => {
    let r = teamState.value ?? [];
    if (filter === "active") {
      r = r.filter((p) => p.dateDisabled == null && p.lastSeen != null);
    } else if (filter === "blocked") {
      r = r.filter((p) => p.dateDisabled != null);
    } else if (filter === "invited") {
      r = r.filter((p) => p.dateDisabled == null && p.lastSeen == null);
    } else {
      // all
    }
    return r;
  }, [teamState.value, filter]);

  const columns = useMemo(() => {
    return [
      {
        field: "picture",
        headerName: "",
        sortable: false,
        width: 48,
        renderCell: (params: GridRenderCellParams) => {
          const p = params.row;
          return (
            <Avatar
              src={p.profilePic}
              alt={p.displayName}
              sx={{ width: 32, height: 32 }}
            />
          );
        },
      },
      {
        field: "firstName",
        headerName: t("ucet.department.table.firstName", "Jméno"),
        sortable: true,
        flex: 1,
        width: 100,
      },
      {
        field: "lastName",
        headerName: t("ucet.department.table.lastName", "Příjmení"),
        sortable: true,
        flex: 1,
        width: 120,
      },
      {
        field: "email",
        headerName: t("ucet.department.table.email", "E-mail"),
        sortable: true,
        flex: 1,
        width: 100,
      },
      {
        field: "phone",
        headerName: t("ucet.department.table.phone", "Telefon"),
        sortable: true,
        flex: 1,
        width: 100,
      },
      {
        field: "section",
        headerName: t("ucet.department.table.section", "Útvar"),
        valueGetter: (params: GridValueGetterParams) => {
          const d = params.row._viewData?.d[departmentGenerichCode]?.details;
          return [d?.section, d?.info].filter(Boolean).join(" / ");
        },
        sortable: true,
        flex: 2,
        width: 200,
      },
      {
        field: "roles",
        headerName: t("ucet.department.table.role", "Role / Zapojení"),
        sortable: false,
        renderCell: rolesRenderer,
        width: 450,
      },
      {
        field: "stats",
        headerName: t("ucet.department.table.status", "Stav"),
        sortable: false,
        renderCell: profileStateRenderer,
        width: 150,
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params: any) =>
          [
            <GridActionsCellItem
              key={"edit"}
              icon={<EditIcon />}
              label={t("ucet.department.table.editAction", "Upravit")}
              onClick={() => openDialog("departmentEditProfile", params.row)}
            />,
            params.row.dateDisabled == null && (
              <GridActionsCellItem
                key={"deactivate"}
                icon={<LockPersonIcon />}
                label={t("ucet.department.table.lockAction", "Zablokovat")}
                onClick={() => {
                  deactivateProfileCall(params.row.id!);
                }}
                showInMenu
              />
            ),
            params.row.dateDisabled != null && (
              <GridActionsCellItem
                key={"reactivate"}
                icon={<LockOpenIcon />}
                label={t("ucet.department.table.unlockAction", "Odemknout")}
                onClick={() => reactivateProfileCall(params.row.id!)}
                showInMenu
              />
            ),
            params.row.dateDisabled == null && (
              <GridActionsCellItem
                key={"roles"}
                icon={<AdminPanelSettingsIcon />}
                label={t(
                  "ucet.department.table.rolesAction",
                  "Nastavení rolí uživatele"
                )}
                onClick={() => openDialog("departmentEditRoles", params.row)}
                showInMenu
              />
            ),
            <GridActionsCellItem
              key={"roles"}
              icon={<AdminPanelSettingsIcon />}
              label={t(
                "ucet.department.table.projectRolesAction",
                "Přehled zapojení uživatele"
              )}
              onClick={() =>
                openDialog("departmentViewProjectRoles", params.row)
              }
              showInMenu
            />,
            // params.row.lastSeen == null && params.row.dateDisabled == null && (
            //   <GridActionsCellItem
            //     key={"resend"}
            //     icon={<ReplayIcon />}
            //     label={t(
            //       "ucet.department.table.resendAction",
            //       "Znovu poslat pozvánku"
            //     )}
            //     onClick={dummy(params.row.id!)}
            //     showInMenu
            //   />
            // ),
            // params.row.lastSeen != null && (
            //   <GridActionsCellItem
            //     key={"transfer"}
            //     icon={<SwapHorizIcon />}
            //     label={t(
            //       "ucet.department.table.transferAction",
            //       "Předat projekty jinému uživateli"
            //     )}
            //     onClick={dummy(params.row.id!)}
            //     showInMenu
            //   />
            // ),
          ].filter(Boolean),
      },
    ];
  }, [
    t,
    rolesRenderer,
    profileStateRenderer,
    departmentGenerichCode,
    deactivateProfileCall,
    reactivateProfileCall,
    openDialog,
  ]);

  const headerConfig: DistaPageHeaderProps = {
    breadcrumbs: [
      {
        title: t("ucet.menu.breadcrumbs.home", "Domů"),
        action: () => (window.location.href = "/ucet/"),
      },
      {
        title: t("ucet.department.adminTitle", "Správa uživatelů resortu"),
      },
    ],
    pageTitle: t("ucet.department.adminTitle", "Správa uživatelů resortu"),
    chipsDefinition: [
      //{label: t('chips.approved', 'Approved'), color: 'primary'},
    ],
    actions: [
      // {
      //   title: t("ucet.department.buttonExport", "Exportovat"),
      //   action: () => {
      //     //
      //   },
      // },
      {
        title: t("ucet.department.buttonInvite", "Pozvat uživatele"),
        action: () => openInviteDialog(),
        primary: true,
      },
      // {
      //     title: t('mainpage.actions.primary', 'Primary action'),
      //     action: () => alert('Primary action clicked'),
      //     primary: true,
      // },
      // {title: t('mainpage.actions.action1', 'First action'), action: () => alert('First action clicked')},
      // {title: t('mainpage.actions.action2', 'Second action'), action: () => alert('Second action clicked')},
    ],
  };

  const localNavigationConfig = useLocalNavigation();

  return (
    <SistaPageLayout localNavigationConfig={localNavigationConfig}>
      <DistaContent headerConfig={headerConfig} fullWidth={true}>
        <Stack
          direction={"column"}
          className={"height-fullscreen"}
          sx={{ width: "calc(100vw - 300px)" }}
        >
          {/*
        <SistaScreenHeader
          title={t("ucet.department.adminTitle", "Správa uživatelů resortu")}
        >
          <Button variant={"outlined"} sx={{ marginRight: 1 }}>
            {t("ucet.department.buttonExport", "Exportovat")}
          </Button>
          <Button variant={"contained"} onClick={openInviteDialog}>
            {t("ucet.department.buttonInvite", "Pozvat uživatele")}
          </Button>
        </SistaScreenHeader>
 */}
          <Stack
            direction={"row"}
            sx={{ backgroundColor: "secondary.light", p: 1 }}
          >
            <FilterListIcon sx={{ mr: 2 }} fontSize={"medium"} />
            {allFilter.map((f) => {
              return (
                <Chip
                  color={f === filter ? "primary" : "default"}
                  key={f}
                  label={filterLabels[f]}
                  onClick={() => setFilter(f)}
                  sx={{ mr: 1 }}
                />
              );
            })}
          </Stack>

          <Box sx={{ flexGrow: 1 }}>
            <DataGrid columns={columns} pageSizeOptions={[100]} rows={rows} />
          </Box>
          <TeamStats team={teamState.value} />
        </Stack>
        {openedDialog == "departmentInvitePerson" && (
          <DepartmentInviteDialog
            departmentId={departmentId}
            onDone={refresh}
          />
        )}
        {openedDialog == "departmentEditRoles" && (
          <DepartmentRolesDialog
            departmentId={departmentId}
            profile={openedDialogData}
            onDone={refresh}
          />
        )}
        {openedDialog == "departmentViewProjectRoles" && (
          <DepartmentProjectRolesDialog
            profile={openedDialogData}
            onDone={refresh}
          />
        )}
        {openedDialog == "departmentEditProfile" && (
          <DepartmentEditDialog
            departmentId={departmentId}
            profile={openedDialogData}
            onDone={refresh}
          />
        )}
      </DistaContent>
    </SistaPageLayout>
  );
};
