import { Stack } from "@mui/material";
import {
  Right,
  SistaConsentInput,
  SistaFormHeader,
  SistaPlainDateElement,
  SmartButton,
  useMaxLengthValidation,
  useMinLengthValidation,
  useNameValidation,
  useReasonableDateValidation,
  useRequiredValidation,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { CreateAccountRequestForm } from "./RegisterAccountDialog";

type RegisterAccountStep2Props = {
  initialValues: CreateAccountRequestForm;
  onSubmit: (d: CreateAccountRequestForm) => void;
  onCancel?: () => void;
};

export const RegisterAccountStep2Form = (props: RegisterAccountStep2Props) => {
  const form = useForm<CreateAccountRequestForm, unknown>({
    defaultValues: props.initialValues,
  });

  const { t } = useTranslation("ucet");
  const required = useRequiredValidation();
  const maxLength = useMaxLengthValidation(37);
  const minLength = useMinLengthValidation(2);
  const nameValidation = useNameValidation();
  const reasonableDate = useReasonableDateValidation();

  const submitStepCallback = useCallback(
    async (d: CreateAccountRequestForm) => {
      await props.onSubmit(d);
      return d;
    },
    [props]
  );

  const [submitStepCall, submitStepState] =
    useSafeSistaAsync(submitStepCallback);

  return (
    <FormContainer onSuccess={submitStepCall} formContext={form}>
      {/*<BlockLeave/>*/}
      <Stack direction="column" spacing={2}>
        <SistaFormHeader
          title={t("ucet.registration.personalTitle", "Osobní údaje")}
          subtitle={t(
            "ucet.registration.personalSubtitle",
            "Vyplňte jméno, příjmení a datum narození."
          )}
        />

        <TextFieldElement
          name="accountDTO.firstName"
          autoComplete={"given-name"}
          label={t("ucet.form.firstName", "Křestní jméno")}
          validation={{
            ...required,
            ...maxLength,
            ...minLength,
            ...nameValidation,
          }}
        />

        <TextFieldElement
          name="accountDTO.lastName"
          autoComplete={"family-name"}
          label={t("ucet.form.lastName", "Příjmení")}
          validation={{
            ...required,
            ...maxLength,
            ...minLength,
            ...nameValidation,
          }}
        />

        <SistaPlainDateElement
          name={"accountDTO.birthDate"}
          label={t("ucet.form.birthDate", "Datum narození")}
          validation={{ ...required, ...reasonableDate }}
        />

        <SistaConsentInput name="accountDTO.consentDigest" />

        <Right>
          <SmartButton
            state={submitStepState}
            sx={{ marginTop: 4, marginBottom: 4 }}
          >
            {t("common.continue", "Pokračovat")}
          </SmartButton>
        </Right>
      </Stack>
    </FormContainer>
  );
};

export default RegisterAccountStep2Form;
