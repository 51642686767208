import { LocalNavigationConfig } from "@sista/fe-commons";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const useLocalNavigation = () => {
  const navigation = useNavigate();
  // const navigation = useHistory().push; for react router v5
  // const navigation = openSelf;

  return useMemo(
    (): LocalNavigationConfig => ({
      localNavigationFn: navigation,
      localRootUrl: "/ucet", // use app base root: /ucet, /notis-admin, ...
    }),
    [navigation]
  );
};