import {
  AlertsContext,
  DistaContent,
  DistaPageHeaderProps,
} from "@sista/dista-ui";
import { SistaPageLayout } from "@sista/fe-commons";
import { IdmClientContext } from "@sista/idm-client";
import { SistaId, UiContext, useSafeSistaAsync } from "@sista/library-stasi";
import { SistaWorkflowTaskGuard } from "@sista/service-workflow-ui";
import { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";
import { useLocalNavigation } from "../../../hooks/useLocalNavigation";
import { OrganizationRegisterApproveView } from "../../dialogs/organization/OrganizationRegisterApproveView";
import { OrganizationRegisterDialog } from "../../dialogs/organization/OrganizationRegisterDialog";

export const OrganizationDelegateScreen = () => {
  const { openDialog, openedDialog, openedDialogData } = useContext(UiContext);
  const alerts = useContext(AlertsContext);

  const { authToken } = useContext(IdmClientContext);

  const { findOrganizationById, findOrganizationRegistration } =
    useRejstrikClient();
  const { t, i18n } = useTranslation("ucet");
  const { organizationId, requestId } = useParams();

  const [getRegistrationCall, getRegistrationState] = useSafeSistaAsync(
    findOrganizationRegistration
  );

  const [getOrganizationCall, getOrganizationState] =
    useSafeSistaAsync(findOrganizationById);

  useEffect(() => {
    if (organizationId) {
      getOrganizationCall({ organizationId: organizationId });
    }
    if (requestId) {
      getRegistrationCall({
        organizationId: organizationId,
        organizationRequestId: requestId,
      });
    }
  }, [getOrganizationCall, getRegistrationCall, organizationId, requestId]);

  const headerConfig: DistaPageHeaderProps = {
    breadcrumbs: [
      {
        title: t("ucet.menu.breadcrumbs.home", "Domů"),
        action: () => (window.location.href = "/ucet/"),
      },
      {
        title: t(
          "ucet.organization.delegateTitle",
          "Zavedení správce organizace"
        ),
      },
    ],
    pageTitle: t(
      "ucet.organization.delegateTitle",
      "Zavedení správce organizace"
    ),
    chipsDefinition: [
      //{label: t('chips.approved', 'Approved'), color: 'primary'},
    ],
    actions: [
      // {
      //   title: t("ucet.organization.buttonExport", "Exportovat"),
      //   action: () => {
      //     //
      //   },
      // },
      // {
      //     title: t('mainpage.actions.primary', 'Primary action'),
      //     action: () => alert('Primary action clicked'),
      //     primary: true,
      // },
      // {title: t('mainpage.actions.action1', 'First action'), action: () => alert('First action clicked')},
      // {title: t('mainpage.actions.action2', 'Second action'), action: () => alert('Second action clicked')},
    ],
  };

  const localNavigationConfig = useLocalNavigation();

  const taskProps = useMemo(() => {
    return {
      workflowId: "organization_registration",
      workflowRoot: "/workflow",
      spravaUkoluRoot: "/sprava-ukolu",
      loggedAuthKey: authToken,
      processContext: [
        new SistaId("organizations", organizationId).asUrn(),
        new SistaId("organizationRegistration", requestId).asUrn(),
      ],
    };
  }, [authToken, organizationId, requestId]);

  return (
    <SistaPageLayout localNavigationConfig={localNavigationConfig}>
      <DistaContent headerConfig={headerConfig} fullWidth={true}>
        {getOrganizationState.value && getRegistrationState.value && (
          <>
            <SistaWorkflowTaskGuard
              {...taskProps}
              nodeId={"createOrganization"}
            >
              <OrganizationRegisterDialog
                organization={getOrganizationState.value}
                registration={getRegistrationState.value}
              />
            </SistaWorkflowTaskGuard>
            <SistaWorkflowTaskGuard {...taskProps} nodeId={"checkRequest"}>
              <OrganizationRegisterApproveView
                organization={getOrganizationState.value}
                registration={getRegistrationState.value}
              />
            </SistaWorkflowTaskGuard>
          </>
        )}
        {/*<Stack*/}
        {/*  direction={"column"}*/}
        {/*  className={"height-fullscreen"}*/}
        {/*  sx={{ width: "calc(100vw - 300px)" }}*/}
        {/*></Stack>*/}
        {/*{openedDialog == "organizationEditProfile" && (*/}
        {/*  <OrganizationEditDialog*/}
        {/*    organizationId={organizationId}*/}
        {/*    profile={openedDialogData}*/}
        {/*    onDone={refresh}*/}
        {/*  />*/}
        {/*)}*/}
      </DistaContent>
    </SistaPageLayout>
  );
};
