import { Box, Button, Stack } from "@mui/material";
import { AlertsContext } from "@sista/dista-ui";
import { IdmClientContext } from "@sista/idm-client";
import {
  Dual,
  LoginDTO,
  SistaDialog,
  SistaFormHeader,
  SmartButton,
  UiContext,
  useEmailValidation,
  useRequiredValidation,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useCallback, useContext } from "react";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { useIdmClient } from "../../../api/utils/useIdmClient";
import { IdmUiContext } from "../../../utils/IdmUiContext";
import ReCaptchaScript, { runReCaptcha } from "../../ui/ReCaptchaScript";
import { SistaPasswordInput } from "../../ui/SistaPasswordInput";
import { TextLanguageSwitcher } from "../../ui/TextLanguageSwitcher";

export const LoginAccountDialog = () => {
  const form = useForm<LoginDTO, unknown>();

  const idm = useContext(IdmClientContext);
  const idmUi = useContext(IdmUiContext);
  const { openDialog, closeDialogs } = useContext(UiContext);
  const alerts = useContext(AlertsContext);
  const { t } = useTranslation("ucet");

  const idmClient = useIdmClient();

  const required = useRequiredValidation();
  const email = useEmailValidation();

  const [loginAccountCall, loginAccountState] = useSafeSistaAsync(
    async (dto: LoginDTO) => {
      const captcha = await runReCaptcha(idmUi.metadata, "login");
      const request = {
        ...dto,
        reCaptchaToken: captcha,
      };
      const la = await idmClient.loginAccount(request);
      idmUi.setRegistrationIntent(null);
      idm.setLoggedAccount(la);
      if (la == null) {
        alerts.showError(
          t("ucet.login.failed", "Neplatné přihlašovací údaje.")
        );
      }
    }
  );

  const forgottenPassword = useCallback(() => {
    openDialog("forgottenPassword");
  }, [openDialog]);

  return (
    <SistaDialog
      onClose={closeDialogs}
      title={t("ucet.login.dialogTitle", "Přihlášení")}
    >
      <SistaFormHeader
        title={t("ucet.login.title", "Přihlášení e-mailem")}
        subtitle={t("ucet.login.subtitle", "Zadejte své přihlašovací údaje")}
      />

      <FormContainer onSuccess={loginAccountCall} formContext={form}>
        {/*<BlockLeave/>*/}
        <Stack direction={"column"} spacing={2}>
          <TextFieldElement
            name="email"
            autoComplete={"email"}
            label={t("ucet.form.email", "E-mail")}
            validation={{ ...required, ...email }}
          />

          <SistaPasswordInput newPassword={false} name="password" />

          <Dual sx={{ paddingTop: 4 }}>
            <Button variant={"outlined"} onClick={forgottenPassword}>
              {t("ucet.login.forgotPassword", "Zapomenuté heslo")}
            </Button>
            <SmartButton state={loginAccountState}>
              {t("ucet.login.buton", "Přihlásit se")}
            </SmartButton>
          </Dual>

          <Box marginTop={4}>
            <TextLanguageSwitcher />
          </Box>
          <ReCaptchaScript metadata={idmUi.metadata} />
        </Stack>
      </FormContainer>
    </SistaDialog>
  );
};
