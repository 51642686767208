import { GridView, ViewHeadline } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  DistaAlertDialog,
  DistaContent,
  DistaContentCard,
} from "@sista/dista-ui";
import { SistaPageLayout } from "@sista/fe-commons";
import {
  LoadingGuard,
  Right,
  TaskDTO,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { SistaChipWithDefaults } from "@sista/sista-chip";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useSpravaUkoluClient } from "../../api/utils/useSpravaUkoluClient";
import { useLocalNavigation } from "../../hooks/useLocalNavigation";
import { filterInterrestingContexts } from "../../utils/filterInterrestingContexts";

export const TasksOverviewScreen = () => {
  const su = useSpravaUkoluClient();

  const { t } = useTranslation("ucet");

  const navigate = useNavigate();

  const [showCards, setShowCards] = useState(true);
  const switchToCards = useCallback(() => setShowCards(true), []);
  const switchToList = useCallback(() => setShowCards(false), []);

  const [listMyTasksCall, listMyTasksState] = useSafeSistaAsync(
    su.listMyUnresolvedTasks
  );
  useEffect(() => {
    listMyTasksCall({ taskContext: [] });
  }, [listMyTasksCall]);

  // const onSubmit = useCallback(async () => { return Promise.resolve("A") }, [depen],);
  // const onClick = useCallback((a: number) => {  return a.toString(); }, [depen],);
  // const val = useMemo(() => { return "AAA";}, [depen]);

  const headerConfig = useMemo(() => {
    return {
      breadcrumbs: [
        {
          title: t("ucet.breadcrumbs.home", "Přehled"),
          action: () => navigate("/"),
        },
        {
          title: t("ucet.breadcrumbs.tasks", "Úkoly"),
          action: () => navigate("/ukoly/"),
        },
      ],
      pageTitle: t("ucet.tasks.title", "Vaše SISTA úkoly"),
    };
  }, [navigate, t]);

  const localNavigationConfig = useLocalNavigation();

  return (
    <SistaPageLayout
      localNavigationConfig={localNavigationConfig}
      searchProps={{
        entityTypes: ["unresolvedTasks", "tasks", "resolvedTasks"],
      }}
    >
      <DistaContent headerConfig={headerConfig} fullWidth={false}>
        <LoadingGuard state={listMyTasksState}>
          {listMyTasksState.value && listMyTasksState.value.length == 0 && (
            <Alert severity={"info"} sx={{ my: 2 }}>
              {t("ucet.tasks.noTasks", "Nemáte žádné úkoly.")}
            </Alert>
          )}
          {listMyTasksState.value && listMyTasksState.value.length > 0 && (
            <DistaContentCard
              sx={{ width: "100%" }}
              title={
                <Box textAlign={"right"}>
                  <IconButton onClick={switchToCards}>
                    <GridView />
                  </IconButton>
                  <IconButton onClick={switchToList}>
                    <ViewHeadline />
                  </IconButton>
                </Box>
              }
            >
              {!showCards && (
                <TableContainer
                  component={Paper}
                  sx={{ marginTop: 1, border: "1px solid #eee" }}
                  elevation={0}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {t("ucet.tasks.table.name", "Název")}
                        </TableCell>
                        <TableCell>
                          {t("ucet.tasks.table.description", "Popis")}
                        </TableCell>
                        <TableCell>
                          {t("ucet.tasks.table.comment", "Komentář")}
                        </TableCell>
                        <TableCell>
                          {t("ucet.tasks.table.context", "Kontext")}
                        </TableCell>
                        <TableCell>
                          {t("ucet.tasks.table.dueDate", "Termín")}
                        </TableCell>
                        <TableCell>&nbsp;</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listMyTasksState.value.map((task) => (
                        <TaskCard
                          task={task}
                          asCard={false}
                          key={task.id}
                        ></TaskCard>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {showCards && (
                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                  {listMyTasksState.value.map((task) => (
                    <Grid item xs={12} md={6} lg={4} key={task.id}>
                      <TaskCard task={task} asCard={true}></TaskCard>
                    </Grid>
                  ))}
                </Grid>
              )}
            </DistaContentCard>
          )}
        </LoadingGuard>
      </DistaContent>
    </SistaPageLayout>
  );
};

type TaskCardProps = {
  task: TaskDTO;
  asCard: boolean;
};

const TaskCard = (props: TaskCardProps) => {
  const { task } = props;

  const { t } = useTranslation("ucet");

  // const onSubmit = useCallback(async () => { return Promise.resolve("A") }, [depen],);
  // const onClick = useCallback((a: number) => {  return a.toString(); }, [depen],);
  // const val = useMemo(() => { return "AAA";}, [depen]);

  const [showAll, setShowAll] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  function trimWithShowAll(description: string) {
    if (description == null) return null;
    if (description.length < 150) return description;
    if (showAll) {
      return description;
    } else {
      return (
        <>
          {description.substring(0, 110) + "..."}
          <br />
          <Right>
            <Button variant={"text"} onClick={() => setShowAll(true)}>
              {t("ucet.tasks.showAll", "Zobrazit vše")}
            </Button>
          </Right>
        </>
      );
    }
  }

  const date = useMemo(() => {
    if (task.dueDate == null) return null;
    const d = new Date(task.dueDate);
    // format to czech date and time
    return d.toLocaleDateString("cs-CZ") + " " + d.toLocaleTimeString("cs-CZ");
  }, [task.dueDate]);

  const hasHelp = task.label.help != null && task.label.help.length > 10;

  const dialog = useMemo(() => {
    return (
      <DistaAlertDialog
        onClose={() => setShowHelp(false)}
        title={t("ucet.tasks.helpDialogTitle", "Nápověda k úkolu")}
        open={true}
        action={{
          children: (
            <Button onClick={() => setShowHelp(false)}>
              {t("common.close", "Zavřít")}
            </Button>
          ),
        }}
      >
        <Box
          sx={{
            width: "fit-content",
          }}
        >
          {task.label.help}
        </Box>
      </DistaAlertDialog>
    );
  }, [t, task.label.help]);

  if (props.asCard)
    return (
      <Card sx={{ marginTop: 1, border: "1px solid #eee" }} elevation={0}>
        {showHelp && dialog}
        <CardContent>
          <Typography variant="h4">{task.label.name}</Typography>
          {date && (
            <Typography variant="body1" color={"#D73A49"}>
              {date}
            </Typography>
          )}
          <Typography variant="body1">
            {trimWithShowAll(task.label.description)}
          </Typography>
          {task.comment && (
            <>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                sx={{ marginTop: 2 }}
              >
                {t("ucet.tasks.commentLabel", "Poznámka:")}
              </Typography>
              {trimWithShowAll(task.comment)}
            </>
          )}
          <Box sx={{ marginTop: 2 }}>
            {filterInterrestingContexts(task.taskContext).map((context) => (
              <Box sx={{ display: "inline-block", marginRight: 1 }}>
                <SistaChipWithDefaults
                  urn={context}
                  key={context}
                  variant={"small"}
                />
              </Box>
            ))}
          </Box>
        </CardContent>
        <CardActions>
          {hasHelp && (
            <Button variant={"text"} onClick={() => setShowHelp(true)}>
              {t("ucet.tasks.helpButton", "NÁPOVĚDA")}
            </Button>
          )}
          <Button variant={"text"} href={task.uiLink}>
            {t("ucet.tasks.solveButton", "VYŘEŠIT")}
          </Button>
        </CardActions>
      </Card>
    );

  return (
    <TableRow key={props.task.id}>
      <TableCell align="left" sx={{ fontWeight: "bold" }}>
        {task.label.name}
        {showHelp && dialog}
      </TableCell>
      <TableCell align="left">
        {trimWithShowAll(task.label.description)}
      </TableCell>
      <TableCell align="left">{trimWithShowAll(task.comment)}</TableCell>
      <TableCell align="left">
        {filterInterrestingContexts(task.taskContext).map((context) => (
          <Box sx={{ display: "inline-block", marginRight: 1 }}>
            <SistaChipWithDefaults
              urn={context}
              key={context}
              variant={"small"}
            />
          </Box>
        ))}
      </TableCell>
      <TableCell align="left">{date}</TableCell>
      <TableCell align="right">
        {hasHelp && (
          <Button variant={"text"} onClick={() => setShowHelp(true)}>
            {t("ucet.tasks.helpButton", "NÁPOVĚDA")}
          </Button>
        )}
        <Button variant={"text"} href={task.uiLink}>
          {t("ucet.tasks.solveButton", "VYŘEŠIT")}
        </Button>
      </TableCell>
    </TableRow>
  );
};
