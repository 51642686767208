import { Add, Clear } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import {
  Profile,
  Relation,
  Right,
  SistaDialog,
  SistaFormHeader,
  UiContext,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";
import { ConfigContext } from "../../../hooks/ConfigContext";

type DepartmentRolesDialogProps = {
  profile: Profile;
  departmentId: string;
  onDone: () => unknown;
};

export const DepartmentRolesDialog = (props: DepartmentRolesDialogProps) => {
  const [relations, setRelations] = useState([
    ...((props.profile as any)._viewData.relations as Relation[])
      .filter((r) => r.refEntityId == props.departmentId)
      .filter((r) => r.role != "DEPARTMENT_EMPLOYEE")
      .filter((r) => r.role != "DEPARTMENT_ZOG"),
  ]);

  const { closeDialogs } = useContext(UiContext);

  const { filterProfileRoles, roleName } = useContext(ConfigContext);
  const { t, i18n } = useTranslation("ucet");

  const { addProfileRoleInDepartment, deleteProfileRoleInDepartment } =
    useRejstrikClient();

  const addProfileRoleImpl = useCallback(
    async (role: string) => {
      const rel = {
        departmentId: props.departmentId,
        profileId: props.profile.id,
        changeReason: "role added from /ucet",
        minorChange: false,
        data: {
          role: role,
          entityId: props.profile.id,
          entityType: "profiles",
          refEntityId: props.departmentId,
          refEntityType: "departments",
        },
      };
      const result = await addProfileRoleInDepartment(rel);

      setRelations([
        ...relations,
        {
          id: result.value,
          ...rel.data,
        },
      ]);
    },
    [
      addProfileRoleInDepartment,
      props.departmentId,
      props.profile.id,
      relations,
    ]
  );
  const [addProfileRoleCall, addProfileRoleState] =
    useSafeSistaAsync(addProfileRoleImpl);

  const deleteProfileRoleImpl = useCallback(
    async (id: string) => {
      await deleteProfileRoleInDepartment({
        departmentId: props.departmentId,
        profileId: props.profile.id,
        relationId: id,
        changeReason: "role removed from /ucet",
        minorChange: false,
        data: id,
      });
      setRelations(relations.filter((r) => r.id !== id));
    },
    [
      deleteProfileRoleInDepartment,
      props.departmentId,
      props.profile.id,
      relations,
    ]
  );
  const [deleteProfileRoleCall, deleteProfileRoleState] = useSafeSistaAsync(
    deleteProfileRoleImpl
  );

  const possibleRoles = useMemo(() => {
    const rs = filterProfileRoles("departments")
      .filter((r) => r.role !== "DEPARTMENT_EMPLOYEE")
      .filter((r) => r.role !== "DEPARTMENT_ZOG");
    return [...rs.filter((r) => !relations.find((rel) => rel.role === r.role))];
  }, [filterProfileRoles, relations]);

  const done = useCallback(() => {
    props.onDone();
    closeDialogs();
  }, [closeDialogs, props]);

  const pending = deleteProfileRoleState.pending || addProfileRoleState.pending;

  return (
    <SistaDialog
      title={t("ucet.department.rolesDialogTitle", "Role")}
      onClose={done}
    >
      <SistaFormHeader
        title={props.profile.firstName + " " + props.profile.lastName}
        subtitle={t(
          "ucet.department.rolesSubtitle",
          "Přidejte nebo odeberte role, které uživatel zastává."
        )}
      />
      <Divider />
      <Stack direction="column" spacing={2}>
        <Typography variant={"h3"} marginTop={2}>
          {t("ucet.roles.profileRoles", "Existující role uživatele")}
        </Typography>
        <Box>
          {relations.map((r) => (
            <span key={r.id}>
              <Chip
                deleteIcon={<Clear />}
                onDelete={async () => !pending && deleteProfileRoleCall(r.id)}
                label={roleName(r.role, i18n.language)}
                sx={{ mr: 1 }}
              />{" "}
            </span>
          ))}

          {(!relations || relations.length == 0) && (
            <Typography variant={"body2"}>
              {t("ucet.roles.noRoles", "Uživatel nemá žádné relevantní role")}
            </Typography>
          )}
        </Box>
        <Divider />
        <Typography variant={"h3"}>
          {t("ucet.roles.possibleRoles", "Možné role")}
        </Typography>
        <Box>
          {possibleRoles.map((r) => (
            <span key={r.role}>
              <Chip
                deleteIcon={<Add />}
                onDelete={async () => !pending && addProfileRoleCall(r.role)}
                label={roleName(r.role, i18n.language)}
                sx={{ mr: 1 }}
              />{" "}
            </span>
          ))}
        </Box>
        {(!possibleRoles || possibleRoles.length == 0) && (
          <Typography variant={"body2"}>
            {t(
              "ucet.roles.noPossibleRoles",
              "V SISTA nejsou další role, které by šlo uživateli přiřadit."
            )}
          </Typography>
        )}
        <Right>
          <LoadingButton loading={pending} onClick={done} variant="contained">
            {t("common.done", "Hotovo")}
          </LoadingButton>
        </Right>
      </Stack>
    </SistaDialog>
  );
};
