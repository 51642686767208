import { Box, Stack } from "@mui/material";
import { AlertsContext } from "@sista/dista-ui";
import {
  Center,
  Right,
  SistaCodeInput,
  SistaDialog,
  SistaFormHeader,
  SmartButton,
  UiContext,
  usePager,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useCallback, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormContainer } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useIdmClient } from "../../../api/utils/useIdmClient";
import { SistaPasswordInput } from "../../ui/SistaPasswordInput";
import { TextLanguageSwitcher } from "../../ui/TextLanguageSwitcher";

type OnboardingPasswordForm = {
  token: string;
  newPassword: string;
};

export const OnboardingPasswordDialog = () => {
  const form = useForm<OnboardingPasswordForm, unknown>();
  const alerts = useContext(AlertsContext);
  const { openDialog, closeDialogs } = useContext(UiContext);
  const { t } = useTranslation("ucet");
  const pager = usePager(0);

  const idmClient = useIdmClient();
  const navigate = useNavigate();

  const invitedEmail = new URL(window.location.href).searchParams.get(
    "invitedEmail"
  );

  useEffect(() => {
    if (invitedEmail == null) {
      alerts.showError(
        t(
          "ucet.error.missingEmail",
          "Chybí email pozvánky, není zřejmé komu byla pozvánka určena."
        )
      );
      navigate("/");
    }
  }, [alerts, invitedEmail, navigate, t]);

  const close = useCallback(() => {
    navigate("/");
    closeDialogs();
  }, [closeDialogs, navigate]);

  const requestTokenImpl = useCallback(async () => {
    await idmClient.lostPasswordRequestToken({
      email: invitedEmail,
      inOnboarding: true,
    });
    pager.nextPage();
  }, [idmClient, invitedEmail, pager]);

  const resetPasswordImpl = useCallback(
    async (dto: OnboardingPasswordForm) => {
      const result = await idmClient.lostPasswordChange({
        ...dto,
        email: invitedEmail,
      });
      if (result) {
        alerts.showSuccess(
          t(
            "ucet.onboardingPassword.success",
            "Heslo bylo úspěšně nastaveno, můžete se poprvé přihlásit."
          )
        );
        openDialog("login");
        navigate("/");
      } else {
        alerts.showError(
          t(
            "ucet.onboardingPassword.failed",
            "Heslo se nepodařilo změnit, kód není pravděpodobně správný."
          )
        );
      }
    },
    [idmClient, invitedEmail, alerts, t, openDialog, navigate]
  );

  const [requestTokenCall, requestTokenState] =
    useSafeSistaAsync(requestTokenImpl);

  const [resetPasswordCall, resetPasswordState] =
    useSafeSistaAsync(resetPasswordImpl);

  const doSubmitCall = pager.page == 0 ? requestTokenCall : resetPasswordCall;
  const doSubmitState =
    pager.page == 0 ? requestTokenState : resetPasswordState;

  return (
    <SistaDialog
      title={t("ucet.onboardingPassword.dialogTitle", "Vítejte v SISTA")}
      onClose={close}
      onBack={pager.page > 0 ? pager.prevPage : undefined}
    >
      <SistaFormHeader
        title={t("ucet.onboardingPassword.title", "Nastavení hesla")}
        subtitle={
          pager.page == 0
            ? t(
                "ucet.onboardingPassword.subtitle",
                "Vítejte v informačním systému SISTA. Nyní je potřeba nastavit vašemu účtu heslo. Na {{invited}} vám nyní zašleme kód, který nastavení umožní. Můžeme pokračovat? Případně, pokud se jedná o Google Účet, je možné toto okno zavřít a přihlásit se přes Google.",
                { invited: invitedEmail }
              )
            : t(
                "ucet.onboardingPassword.changeSubtitle",
                "Zkopírujte kód, který vám byl zaslán a použijte ho pro nastavení hesla."
              )
        }
      />

      <FormContainer onSuccess={doSubmitCall} formContext={form}>
        {/*<BlockLeave/>*/}
        <Stack direction={"column"}>
          {pager.page == 1 && (
            <>
              <Center sx={{ marginBottom: 4 }}>
                <SistaCodeInput name="token" />
              </Center>
              <SistaPasswordInput
                newPassword={true}
                name="newPassword"
                label={t("ucet.form.newPassword", "Nové heslo")}
              />
            </>
          )}

          <Right sx={{ marginTop: 4 }}>
            <SmartButton state={doSubmitState}>
              {t("common.continue", "Pokračovat")}
            </SmartButton>
          </Right>

          <Box marginTop={4}>
            <TextLanguageSwitcher />
          </Box>
        </Stack>
      </FormContainer>
    </SistaDialog>
  );
};
