import { Box, Divider, Stack, Typography } from "@mui/material";
import { Profile } from "@sista/library-stasi";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type TeamStatsProps = {
  team?: Profile[];
};

export const TeamStats = (props: TeamStatsProps) => {
  const { t } = useTranslation("ucet");

  const stats = useMemo(() => {
    const r = props.team ?? ([] as Profile[]);
    const all = r.length;
    const active = r.filter(
      (p) => p.dateDisabled == null && p.lastSeen != null
    ).length;
    const blocked = r.filter((p) => p.dateDisabled != null).length;
    const invited = r.filter(
      (p) => p.dateDisabled == null && p.lastSeen == null
    ).length;
    return [all, active, blocked, invited];
  }, [props]);

  return (
    <Stack direction="row" sx={{ paddingTop: 2 }}>
      <Box sx={{ p: 4 }}>
        <Typography variant={"body1"}>
          {t("ucet.department.stats.all", "Počet uživatelů")}
        </Typography>
        <Typography variant={"h2"}>{stats[0]}</Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box sx={{ p: 4 }}>
        <Typography variant={"body1"}>
          {t("ucet.department.stats.active", "Aktivní uživatelé")}
        </Typography>
        <Typography variant={"h2"}>{stats[1]}</Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box sx={{ p: 4 }}>
        <Typography variant={"body1"}>
          {t("ucet.department.stats.blocked", "Zablokovaní uživatelé")}
        </Typography>
        <Typography variant={"h2"}>{stats[2]}</Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box sx={{ p: 4 }}>
        <Typography variant={"body1"}>
          {t("ucet.department.stats.invited", "Pozvaní uživatelé")}
        </Typography>
        <Typography variant={"h2"}>{stats[3]}</Typography>
      </Box>
    </Stack>
  );
};
