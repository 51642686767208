import { Stack } from "@mui/material";
import { DistaContent, DistaPageHeaderProps } from "@sista/dista-ui";
import { SistaPageLayout } from "@sista/fe-commons";
import { IdmClientContext } from "@sista/idm-client";
import {
  LoadingGuard,
  useActiveMenuItem,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { PersonDetailPanel } from "./PersonDetailPanel";
import { useRejstrikClient } from "../../api/utils/useRejstrikClient";
import { useLocalNavigation } from "../../hooks/useLocalNavigation";

export const PersonSettingsScreen = () => {
  const rej = useRejstrikClient();
  const { loggedAccount } = useContext(IdmClientContext);
  const [loadPersonCall, loadPersonState] = useSafeSistaAsync(
    rej.findPersonByIdByDate
  );
  const { t } = useTranslation("ucet");

  useActiveMenuItem("settings-profiles");

  useEffect(() => {
    loadPersonCall({
      personId: loggedAccount.personId,
    });
  }, [loadPersonCall, loggedAccount.activeProfileId]);

  const data = (loadPersonState.value as any)?._viewData;

  const headerConfig: DistaPageHeaderProps = {
    breadcrumbs: [
      {
        title: t("ucet.menu.breadcrumbs.home", "Domů"),
        action: () => (window.location.href = "/ucet/"),
      },
      {
        title: t("person.detail.basic.headline", "Osobní údaje"),
      },
    ],
    pageTitle: t("person.detail.basic.headline", "Osobní údaje"),
    chipsDefinition: [
      //{label: t('chips.approved', 'Approved'), color: 'primary'},
    ],
    actions: [
      // {
      //     title: t('mainpage.actions.primary', 'Primary action'),
      //     action: () => alert('Primary action clicked'),
      //     primary: true,
      // },
      // {title: t('mainpage.actions.action1', 'First action'), action: () => alert('First action clicked')},
      // {title: t('mainpage.actions.action2', 'Second action'), action: () => alert('Second action clicked')},
    ],
  };

  const localNavigationConfig = useLocalNavigation();

  return (
    <SistaPageLayout localNavigationConfig={localNavigationConfig}>
      <DistaContent headerConfig={headerConfig} fullWidth={true}>
        <Stack direction={"column"} sx={{ p: 2 }}>
          <LoadingGuard state={loadPersonState}>
            <PersonDetailPanel person={loadPersonState.value} />
          </LoadingGuard>
        </Stack>
      </DistaContent>
    </SistaPageLayout>
  );
};
