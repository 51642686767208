import { Box, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import {
  LoadingGuard,
  Organization,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";

export type OrganizationPropertyTiesContentProps = {
  organization: Organization;
};

export type RenderTiesProps = {
  tieCode: number;
  ownerships: any[];
};

export const RenderTies: React.FC<RenderTiesProps> = (
  props: RenderTiesProps
) => {
  const { t } = useTranslation("ucet");
  return (
    <Stack direction={"column"} spacing={2} sx={{ alignItems: "center" }}>
      {props.ownerships &&
        props.ownerships.length > 0 &&
        props.ownerships?.map((owner: any) => {
          if (owner.direction !== props.tieCode) return null;
          return (
            <Stack
              key={owner.lastName}
              direction={"column"}
              sx={{ width: "100%" }}
            >
              <Stack direction={"row"} spacing={2} sx={{}}>
                <Typography variant="body1Hl" sx={{ fontWeight: "normal" }}>
                  {t("ucet.organizationPropertyTies.name", "Název: ")}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="body1Hl" sx={{ fontWeight: "bold" }}>
                  {owner.name ? owner.name : "-"}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={2} sx={{}}>
                <Typography variant="body1Hl" sx={{ fontWeight: "normal" }}>
                  {t("ucet.organizationPropertyTies.tieName", "Název vazby: ")}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="body1Hl" sx={{ fontWeight: "normal" }}>
                  {owner.type_txt ? owner.type_txt : "-"}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={2} sx={{}}>
                <Typography variant="body1Hl" sx={{ fontWeight: "normal" }}>
                  {t("ucet.organizationPropertyTies.part", "Procento: ")}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="body1Hl" sx={{ fontWeight: "normal" }}>
                  {owner.value_pct ? owner.value_pct : "-"}
                </Typography>
              </Stack>
              <Divider />
            </Stack>
          );
        })}
    </Stack>
  );
};

export const OrganizationPropertyTiesContent = (
  props: OrganizationPropertyTiesContentProps
) => {
  const { t } = useTranslation("ucet");
  const rej = useRejstrikClient();

  const [getOrganizationCustomDataCall, getOrganizationCustomDataState] =
    useSafeSistaAsync(rej.getOrganizationCustomData);

  useEffect(() => {
    getOrganizationCustomDataCall({ organizationId: props.organization.id });
  }, [props.organization.id, getOrganizationCustomDataCall]);

  const organizationCustomData = useMemo(() => {
    if (getOrganizationCustomDataState.value) {
      return getOrganizationCustomDataState.value;
    }
    return {};
  }, [getOrganizationCustomDataState.value]);

  return (
    <Paper variant="infoCard" sx={{ mt: 2 }}>
      <LoadingGuard state={getOrganizationCustomDataState}>
        <Grid container infoCardMultiColumns>
          <Grid item md={6} xs={12}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t(
                "ucet.organizationPropertyTies.propertyTitle",
                "Majetkové vazby"
              )}
            </Typography>
            <RenderTies
              tieCode={-1}
              ownerships={
                (organizationCustomData as any)?.rejstrik?.ownerships ?? null
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t("ucet.organizationPropertyTies.custodyTitle", "Řídící vazby")}
            </Typography>
            <RenderTies
              tieCode={1}
              ownerships={
                (organizationCustomData as any)?.rejstrik?.ownerships ?? null
              }
            />
          </Grid>
        </Grid>
      </LoadingGuard>
    </Paper>
  );
};
