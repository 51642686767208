import { Box, Stack } from "@mui/material";
import { IdmClientContext } from "@sista/idm-client";
import {
  AddNiaEmailDTO,
  Right,
  SistaDialog,
  SistaFormHeader,
  SmartButton,
  useEmailValidation,
  useMaxLengthValidation,
  useRequiredValidation,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { useIdmClient } from "../../../api/utils/useIdmClient";
import { TextLanguageSwitcher } from "../../ui/TextLanguageSwitcher";

type NiaEmailNeededForm = {
  addNiaAccount: AddNiaEmailDTO;
};

export const NiaEmailNeededDialog = () => {
  const idm = useContext(IdmClientContext);
  const form = useForm<NiaEmailNeededForm, unknown>({
    defaultValues: {
      addNiaAccount: {
        email: null,
        token: idm.loggedAccount.flagParams,
      },
    },
  });
  const { t } = useTranslation("ucet");

  const required = useRequiredValidation();
  const emailValidation = useEmailValidation();
  const maxLength = useMaxLengthValidation(128);

  const { resolveNiaEmailNeeded } = useIdmClient();

  const [finishAccountCall, finishAccountState] = useSafeSistaAsync(
    async (dto: NiaEmailNeededForm) => {
      const request = {
        ...dto.addNiaAccount,
      };
      await resolveNiaEmailNeeded(request).then(idm.setLoggedAccount);
    }
  );

  return (
    <SistaDialog
      title={t("ucet.nia.finishDialogTitle", "Doplnění údajů")}
      onClose={() => idm.logout()}
    >
      <SistaFormHeader
        title={t("ucet.nia.finishTitle", "Dokončení registrace")}
        subtitle={t(
          "ucet.nia.finishSubtitle",
          "Doplňte nebo upravte Vaše údaje."
        )}
      />

      <FormContainer onSuccess={finishAccountCall} formContext={form}>
        <Stack direction="column" spacing={2}>
          <TextFieldElement
            name="addNiaAccount.email"
            autoComplete={"email"}
            label={t("ucet.form.email", "Email")}
            validation={{
              ...required,
              ...maxLength,
              ...emailValidation,
            }}
          />
          <Right sx={{ marginTop: 4 }}>
            <SmartButton state={finishAccountState}>
              {t("common.continue", "Pokračovat")}
            </SmartButton>
          </Right>

          <Box marginTop={4}>
            <TextLanguageSwitcher></TextLanguageSwitcher>
          </Box>
        </Stack>
      </FormContainer>
    </SistaDialog>
  );
};
