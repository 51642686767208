import { Typography } from "@mui/material";
import { IdmClientContext } from "@sista/idm-client";
import {
  Center,
  Dual,
  LoadingGuard,
  SistaCodeInput,
  SistaDialog,
  SistaFormHeader,
  SmartButton,
  UiContext,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useCallback, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormContainer } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { useIdmClient } from "../../../api/utils/useIdmClient";

export type ConnectAccountForm = {
  connectAccountToken?: string;
};

export const MergePersonDialog = () => {
  const { setLoggedAccount, loggedAccount } = useContext(IdmClientContext);
  const ui = useContext(UiContext);
  const { t } = useTranslation("ucet");

  const {
    findSimilarPerson,
    resolveVerifySimilarPerson,
    resolveCheckSimilarPersonNeeded,
    requestConnectAccountToken,
  } = useIdmClient();

  const connectForm = useForm<ConnectAccountForm>({
    defaultValues: {
      connectAccountToken: "",
    },
  });

  const [findSimilarPersonCall, findSimilarPersonState] =
    useSafeSistaAsync(findSimilarPerson);
  useEffect(() => {
    findSimilarPersonCall({
      firstName: loggedAccount.firstName,
      lastName: loggedAccount.lastName,
      birthDate: {
        year: parseInt(loggedAccount.flagVariables["birthDateYear"]),
        month: parseInt(loggedAccount.flagVariables["birthDateMonth"]),
        day: parseInt(loggedAccount.flagVariables["birthDateDay"]),
      },
    });
  }, [
    findSimilarPersonCall,
    loggedAccount.firstName,
    loggedAccount.flagVariables,
    loggedAccount.lastName,
  ]);

  const [getConnectTokenCall, getConnectTokenState] = useSafeSistaAsync(
    useCallback(async () => {
      return await requestConnectAccountToken({
        value: findSimilarPersonState.value?.connectAccountConfirmationToken,
      });
    }, [
      findSimilarPersonState.value?.connectAccountConfirmationToken,
      requestConnectAccountToken,
    ])
  );

  const [registerWithTokenCall, registerWithTokenState] = useSafeSistaAsync(
    useCallback(
      async (t: ConnectAccountForm) => {
        const result = await resolveVerifySimilarPerson({
          value: t.connectAccountToken,
        });
        setLoggedAccount(result);
      },
      [resolveVerifySimilarPerson, setLoggedAccount]
    )
  );

  const [cancelMergeCall, cancelMergeState] = useSafeSistaAsync(
    useCallback(async () => {
      // Odebereeme flag checkSimilarPersonNeeded
      const result = await resolveCheckSimilarPersonNeeded({});
      setLoggedAccount(result);
    }, [resolveCheckSimilarPersonNeeded, setLoggedAccount])
  );

  const pageConfirm =
    getConnectTokenState.value == null && findSimilarPersonState.value != null;
  const pageSubmit = !pageConfirm && findSimilarPersonState.value != null;

  return (
    <SistaDialog
      // onClose={() => ui.openDialog(null)}
      // onBack={goBack}
      title={t("ucet.connect.dialogTitle", "Propojení účtů")}
    >
      <SistaFormHeader
        title={t("ucet.connect.title", "Propojení účtů")}
        subtitle={t(
          "ucet.connect.subtitle",
          "V SISTA se již nachází záznam, který se podobá zadávanému účtu."
        )}
      />
      <LoadingGuard state={findSimilarPersonState}>
        {pageConfirm && (
          <>
            <Typography variant={"body1"}>
              {t("ucet.connect.message", "Evidujeme účet s emailem:", {
                email: findSimilarPersonState.value.anonymizedEmail,
              })}
            </Typography>
            <Typography
              variant={"body1"}
              fontWeight={"bold"}
              textAlign={"center"}
              marginY={1}
            >
              {findSimilarPersonState.value.anonymizedEmail}
            </Typography>
            <Typography variant={"body1"}>
              {t(
                "ucet.connect.question",
                "Nejedná se o Váš již existující SISTA účet?"
              )}
            </Typography>

            <Dual sx={{ marginTop: 6 }}>
              <SmartButton
                onClick={cancelMergeCall}
                variant={"outlined"}
                state={cancelMergeState}
              >
                {t("ucet.connect.cancelButton", "Ne, to není můj účet")}
              </SmartButton>
              <SmartButton
                onClick={getConnectTokenCall}
                state={getConnectTokenState}
              >
                {t("ucet.connect.confirmButton", "Ano, to je můj účet")}
              </SmartButton>
            </Dual>
          </>
        )}
        {pageSubmit && (
          <FormContainer
            defaultValues={{}}
            onSuccess={registerWithTokenCall}
            formContext={connectForm}
          >
            <Typography marginBottom={6} typography={"body1"}>
              {t(
                "ucet.connect.enterToken",
                "Na email jsme odeslali jednorázový kód pro propojení účtů."
              )}
            </Typography>

            <Center>
              <SistaCodeInput name="connectAccountToken" />
            </Center>

            <Dual sx={{ marginTop: 6 }}>
              <SmartButton
                onClick={getConnectTokenCall}
                state={getConnectTokenState}
                variant={"outlined"}
              >
                {t("ucet.connect.sendAgainButton", "Poslat znovu")}
              </SmartButton>
              <SmartButton state={registerWithTokenState}>
                {t("ucet.connect.doConnectButton", "Propojit účty")}
              </SmartButton>
            </Dual>
          </FormContainer>
        )}
      </LoadingGuard>
    </SistaDialog>
  );
};
