import { RoleConfig, useSafeSistaAsync } from "@sista/library-stasi";
import React, { useEffect, useMemo, useState } from "react";

import { useRejstrikClient } from "../api/utils/useRejstrikClient";

export type ConfigContextType = {
  allRoles: RoleConfig[];
  filterRoles: (fromType: string, toType: string) => RoleConfig[];
  filterProfileRoles: (toType: string) => RoleConfig[];
  roleName: (role: string, lang: string) => string;
};

export const ConfigContext = React.createContext<ConfigContextType>(null);
ConfigContext.displayName = "ConfigContext";

type ConfigProviderProps = {
  children: React.ReactNode;
};

export const ConfigContextProvider = (props: ConfigProviderProps) => {
  const [allRoles, setAllRoles] = useState([] as RoleConfig[]);
  const { getAllRoles } = useRejstrikClient();

  const [getAllRolesCall] = useSafeSistaAsync(getAllRoles);

  useEffect(() => {
    getAllRolesCall(null).then(setAllRoles);
  }, [getAllRolesCall]);

  const result = useMemo(() => {
    return {
      allRoles: allRoles,
      filterRoles: (fromType: string, toType: string) =>
        allRoles.filter(
          (r) => r.entityType === fromType && r.refEntityType === toType
        ),
      filterProfileRoles: (toType: string) =>
        allRoles.filter(
          (r) => r.entityType === "profiles" && r.refEntityType === toType
        ),
      roleName(role: string, lang: string) {
        const r = allRoles.find((r) => r.role === role);
        if (!r) return "???";
        return r.name[lang];
      },
    };
  }, [allRoles]);

  return (
    <ConfigContext.Provider value={result}>
      {props.children}
    </ConfigContext.Provider>
  );
};
