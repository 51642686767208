import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  defineStyles,
  DistaAvatar,
  DistaAvatarSize,
  DistaInfoListBlock,
  TypographyVariantsType,
} from "@sista/dista-ui";
// import { gridThemeDefinition } from "@sista/dista-ui/dist/ts/theme/components/gridThemeDefinition";
// import { paperThemeDefinition } from "@sista/dista-ui/dist/ts/theme/components/paperThemeDefinition";
import { IdmClientContext } from "@sista/idm-client";
import {
  ChangePasswordDTO,
  countryName,
  formatPlainDate,
  isTrue,
  Person,
  UiContext,
  useEditableDetailHeadline,
} from "@sista/library-stasi";
import { useEditorDialogHandle } from "@sista/library-stasi/dist/hooks/useEditorDialogHandle";
import { ReactNode, useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { PersonDataForm } from "./PersonDataForm";
import { useIdmClient } from "../../api/utils/useIdmClient";
import { useRejstrikClient } from "../../api/utils/useRejstrikClient";
import { useGender } from "../../hooks/useGender";
import { detailItem } from "../../utils/detailitem";
import { PersonChangePasswordForm } from "../dialogs/settings/PersonChangePasswordForm";
import { TwoAuthSettingsDialog } from "../dialogs/settings/TwoAuthSettingsDialog";

export type PersonDetailPanelProps = {
  person: Person;
};

export const PersonDetailPanel = (props: PersonDetailPanelProps) => {
  const rej = useRejstrikClient();
  const idm = useIdmClient();
  const navigate = useNavigate();
  const { t } = useTranslation("ucet");
  const { loggedAccount } = useContext(IdmClientContext);
  const { refresh, openDialog, openedDialog } = useContext(UiContext);
  const view = useParams().view ?? "detail";

  const styles = defineStyles({
    textWithCopyButton: {
      "& span:first-of-type": {
        pr: 1,
      },
    },
    userDataBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& p:first-of-type": {
        pb: 1,
      },
    },
  });

  const detailHeadline = useEditableDetailHeadline();
  const { genderName, genders } = useGender();

  const getPersonData = useCallback(
    () =>
      rej.findPersonByIdByDate({
        personId: loggedAccount.personId,
      }),
    [rej, loggedAccount.personId]
  );

  const savePersonData = useCallback(
    (d: Person) => {
      return rej.updatePersonByOwner({
        personId: loggedAccount.personId,
        changeReason: "edit from ui by owner",
        minorChange: false,
        data: d,
      });
    },
    [rej, loggedAccount.personId]
  );

  const personalData = useMemo(() => {
    return {
      label: detailHeadline(
        t("person.detail.basic.headline", "Osobní údaje"),
        getPersonData,
        savePersonData,
        PersonDataForm
      ),
      infoListItems: [
        detailItem(
          t("ucet.person.titlesBefore", "Tituly před jménem"),
          props.person.titlesBefore
        ),
        detailItem(t("common.firstName", "Jméno"), props.person.firstName),
        detailItem(t("common.lastName", "Příjmení"), props.person.lastName),
        detailItem(
          t("ucet.person.titlesAfter", "Tituly za jménem"),
          props.person.titlesAfter
        ),
        //detailItem(t("ucet.person.phone", "Telefon"), props.person.phone),
        detailItem(
          t("ucet.person.birthDate", "Datum narození"),
          formatPlainDate(props.person.birthDate)
        ),
        detailItem(
          t("ucet.person.nationalIdNumber", "Rodné číslo"),
          props.person.nationalIdNumber
        ),
        detailItem(
          t("ucet.person.nationality", "Státní příslušnost"),
          countryName(props.person.nationality)
        ),
        detailItem(
          t("ucet.person.gender", "Pohlaví"),
          genderName(props.person.gender)
        ),
      ],
    };
  }, [
    detailHeadline,
    t,
    getPersonData,
    savePersonData,
    props.person.titlesBefore,
    props.person.firstName,
    props.person.lastName,
    props.person.titlesAfter,
    props.person.birthDate,
    props.person.nationalIdNumber,
    props.person.nationality,
    props.person.gender,
    genderName,
  ]);

  const getChangePasswordData = useCallback(() => {
    return Promise.resolve<ChangePasswordDTO>({
      oldPassword: "",
      newPassword: "",
    });
  }, []);

  const saveChangePasswordData = useCallback(
    async (d: ChangePasswordDTO) => {
      const r = await idm.changePassword(d);
      if (!isTrue(r)) {
        throw new Error(
          t(
            "ucet.person.changePassword.error",
            "Heslo se nepodařilo změnit, zadáváte správné stávající heslo?"
          )
        );
      }
    },
    [idm, t]
  );

  const {
    openedEditorDialog: openedChangedEditorHandle,
    openEditorDialog: openChangedEditorHandle,
  } = useEditorDialogHandle({
    getData: getChangePasswordData,
    saveData: saveChangePasswordData,
    editor: PersonChangePasswordForm,
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Paper variant="infoCard">
            <Box sx={styles.userDataBox}>
              <DistaAvatar
                src={props.person.profilePic}
                size={DistaAvatarSize.xxlarge}
              />
              <Typography variant={TypographyVariantsType.h3}>
                {props.person.firstName} {props.person.lastName}
              </Typography>
            </Box>
            <Divider variant="middle" />
            <DistaInfoListBlock {...personalData} />
          </Paper>
        </Grid>
        <Grid item md={3} xs={12}>
          <Stack direction={"column"} spacing={2}>
            <Typography variant={TypographyVariantsType.h3}>
              {t("ucet.person.options", "Další možnosti")}
            </Typography>
            <Button
              type="button"
              onClick={openChangedEditorHandle}
              variant={"text"}
              sx={{ textAlign: "left", justifyContent: "left" }}
            >
              {t("ucet.person.changePassword.button", "Změnit heslo")}
            </Button>
            {openedChangedEditorHandle}
            <Button
              type="button"
              onClick={() => openDialog("twoAuthSettings")}
              variant={"text"}
              sx={{ textAlign: "left", justifyContent: "left" }}
            >
              {t("ucet.twoAuth.button", "Nastavit dvoufaktorové přihlašování")}
            </Button>
            {openedDialog == "twoAuthSettings" && <TwoAuthSettingsDialog />}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
