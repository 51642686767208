import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, CircularProgress, Typography } from "@mui/material";
import { Center, Right, SistaDialog, UiContext } from "@sista/library-stasi";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const RegistrationFinishDialog = (props: {
  doRedirect: () => void;
  working: boolean;
}) => {
  // const { id } = useParams();
  const ui = useContext(UiContext);
  const { t } = useTranslation("ucet");

  useEffect(() => {
    return () => {
      setTimeout(props.doRedirect, 2000);
    };
  }, [props.doRedirect]);

  return (
    <SistaDialog
      // onClose={() => ui.openDialog(null)}
      title={t("ucet.registration.finishedTitle", "Hotovo")}
    >
      <Center>
        <CheckCircleIcon
          sx={{ width: "5rem", height: "5rem" }}
          color={"success"}
        />
      </Center>
      <Typography variant={"body1"} marginY={2}>
        {t(
          "ucet.registration.finishBody",
          "Pokračujte stisknutím tlačítka níže nebo vyčkejte na automatické přesměrování."
        )}
      </Typography>
      <Right>
        <Button variant={"contained"} onClick={props.doRedirect}>
          {props.working && (
            <>
              <CircularProgress
                size={20}
                sx={{ marginRight: 1, color: "white" }}
              />
              {t("common.working", "Pracuji...")}
            </>
          )}
          {!props.working && t("common.continue", "Pokračovat")}
        </Button>
      </Right>
    </SistaDialog>
  );
};
