/* eslint-disable import/no-named-as-default-member */

// https://stackoverflow.com/questions/43898155/react-i18next-not-loading-json-translation-files-in-react-app-created-with-creat

import { logi } from "@sista/library-stasi";
import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

const namespaceConfigs = [
  {
    ns: "workflow",
    baseUrl: process.env.REACT_APP_WORKFLOW_UI_ROOT + "/translations",
  },
  {
    ns: "ucet",
    baseUrl: process.env.REACT_APP_BASEPATH + "/translations",
  },
];

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "cs",
    ns: namespaceConfigs.map((c) => c.ns),
    supportedLngs: ["en", "cs"],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: function (lngs: string[], nss: string[]) {
        const config = namespaceConfigs.find((c) => c.ns === nss[0]);
        if (!config) {
          return undefined;
        }
        logi(`${config.baseUrl}/${lngs[0]}.json`);
        return `${config.baseUrl}/${lngs[0]}.json`;
      },
    },
  });

export default i18n;
