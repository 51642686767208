import { Box, Typography } from "@mui/material";
import { AlertsContext } from "@sista/dista-ui";
import { IdmClientContext } from "@sista/idm-client";
import {
  Center,
  Dual,
  isTrue,
  SistaCodeInput,
  SistaDialog,
  SmartButton,
  useSafeSistaAsync,
  VerifyAccountEmailDTO,
} from "@sista/library-stasi";
import { useCallback, useContext } from "react";
import { useForm } from "react-hook-form";
import { FormContainer } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { useIdmClient } from "../../../api/utils/useIdmClient";
import { TextLanguageSwitcher } from "../../ui/TextLanguageSwitcher";

export const VerifyAccountDialog = () => {
  const form = useForm<VerifyAccountEmailDTO, unknown>();
  const { loggedAccount, setLoggedAccount } = useContext(IdmClientContext);
  const { t } = useTranslation("ucet");
  const idmClient = useIdmClient();
  const { showSuccess, showError } = useContext(AlertsContext);

  const verifyAccountImpl = useCallback(
    async (dto: VerifyAccountEmailDTO) => {
      const request = {
        ...dto,
        accountId: loggedAccount!.usedAccountId,
      };
      const result = await idmClient.verifyAccountEmail(request);
      if (isTrue(result)) {
        await idmClient.getLoggedAccount({ noCache: true }).then((la) => {
          setLoggedAccount(la);
        });
        showSuccess(
          t(
            "ucet.registration.verificationSuccess",
            "Aktivace proběhla úspěšně"
          )
        );
      } else {
        showError(
          t(
            "ucet.registration.verificationError",
            "Aktivace se nezdařila. Kód není správně nebo již vypršel, zkuste si ho nechat zaslat znovu."
          )
        );
      }
    },
    [idmClient, loggedAccount, setLoggedAccount, showError, showSuccess, t]
  );

  const [verifyAccountCall, verifyAccountState] =
    useSafeSistaAsync(verifyAccountImpl);

  const resendCodeImpl = useCallback(async () => {
    await idmClient.resendEmailVerificationCode(null);
    showSuccess(
      t(
        "ucet.registration.verifyResendCodeSuccess",
        "Znovuzaslání kódu se podařilo"
      )
    );
  }, [idmClient, showSuccess, t]);

  const [resendCodeCall, resendCodeState] = useSafeSistaAsync(resendCodeImpl);

  return (
    <SistaDialog
      onClose={() => null}
      title={t("ucet.registration.verifyDialogTitle", "Aktivace účtu")}
    >
      <Typography marginBottom={2} typography={"h4"}>
        {t("ucet.registration.verifyTitle", "Aktivační kód")}
      </Typography>
      <Typography marginBottom={4} typography={"body1"}>
        {t(
          "ucet.registration.verifySubtitle",
          "Váš účet byl vytvořen. Na zadanou e-mailovou adresu jsme odeslali šestimístný aktivační kód, kterým ověříme správnost zadané e-mailové adresy."
        )}
      </Typography>

      <FormContainer
        defaultValues={{}}
        onSuccess={verifyAccountCall}
        formContext={form}
      >
        {/*<BlockLeave/>*/}

        <Center>
          <SistaCodeInput name="verificationToken" />
        </Center>

        <Dual sx={{ marginTop: 4 }}>
          <SmartButton
            state={resendCodeState}
            primary={false}
            onClick={resendCodeCall}
          >
            {t("ucet.registration.resendVerifyButton", "Zaslat znovu")}
          </SmartButton>
          <SmartButton state={verifyAccountState}>
            {t("ucet.registration.verifyButton", "Aktivovat")}
          </SmartButton>
        </Dual>

        <Box marginTop={4}>
          <TextLanguageSwitcher />
        </Box>
      </FormContainer>
    </SistaDialog>
  );
};
