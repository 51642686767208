import { Box, Stack } from "@mui/material";
import { IdmClientContext } from "@sista/idm-client";
import {
  PersonDetailsNeeded,
  Right,
  SistaConsentInput,
  SistaDialog,
  SistaFormHeader,
  SistaPlainDateElement,
  SmartButton,
  useMaxLengthValidation,
  useMinLengthValidation,
  useNameValidation,
  useReasonableDateValidation,
  useRequiredValidation,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { useIdmClient } from "../../../api/utils/useIdmClient";
import { TextLanguageSwitcher } from "../../ui/TextLanguageSwitcher";

type AccountDetailsForm = {
  details: PersonDetailsNeeded;
  consentChecked: boolean;
};

export const PersonDetailsDialog = () => {
  const idm = useContext(IdmClientContext);
  const form = useForm<AccountDetailsForm, unknown>({
    defaultValues: {
      details: {
        firstName: null,
        lastName: null,
        birthDate: null,
      },
    },
  });
  const { t } = useTranslation("ucet");

  const { loggedAccount } = useContext(IdmClientContext);

  const token = useMemo(() => {
    return loggedAccount.flagParams;
  }, [loggedAccount]);

  const required = useRequiredValidation();
  const reasonableDate = useReasonableDateValidation();
  const nameValidation = useNameValidation();
  const maxLength = useMaxLengthValidation(37);
  const minLength = useMinLengthValidation(2);

  const { resolvePersonDetailsNeeded } = useIdmClient();

  const [finishAccountCall, finishAccountState] = useSafeSistaAsync(
    async (dto: AccountDetailsForm) => {
      const request: PersonDetailsNeeded = {
        token: token,
        ...dto.details,
      };
      await resolvePersonDetailsNeeded(request).then(idm.setLoggedAccount);
    }
  );

  return (
    <SistaDialog
      title={t("ucet.google.finishDialogTitle", "Doplnění údajů")}
      onClose={() => idm.logout()}
    >
      <SistaFormHeader
        title={t("ucet.google.finishTitle", "Dokončení registrace")}
        subtitle={t(
          "ucet.google.finishSubtitle",
          "Doplňte nebo upravte Vaše údaje."
        )}
      />

      <FormContainer onSuccess={finishAccountCall} formContext={form}>
        <Stack direction="column" spacing={2}>
          <TextFieldElement
            name="details.firstName"
            autoComplete={"given-name"}
            label={t("ucet.form.firstName", "Křestní jméno")}
            validation={{
              ...required,
              ...maxLength,
              ...minLength,
              ...nameValidation,
            }}
          />

          <TextFieldElement
            name="details.lastName"
            autoComplete={"family-name"}
            label={t("ucet.form.lastName", "Příjmení")}
            validation={{
              ...required,
              ...maxLength,
              ...minLength,
              ...nameValidation,
            }}
          />

          <SistaPlainDateElement
            name={"details.birthDate"}
            label={t("ucet.form.birthDate", "Datum narození")}
            validation={{ ...required, ...reasonableDate }}
          />

          <SistaConsentInput name="consentChecked" />

          <Right sx={{ marginTop: 4 }}>
            <SmartButton state={finishAccountState}>
              {t("common.continue", "Pokračovat")}
            </SmartButton>
          </Right>

          <Box marginTop={4}>
            <TextLanguageSwitcher></TextLanguageSwitcher>
          </Box>
        </Stack>
      </FormContainer>
    </SistaDialog>
  );
};
