import { Container } from "@mui/material";
import { DistaContent, DistaPageHeaderProps } from "@sista/dista-ui";
import { UiContext } from "@sista/library-stasi";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { IdmUiContext } from "../../utils/IdmUiContext";
import { IntentPicker } from "../ui/IntentPicker";

// import {readTemplate, updateTemplate} from '../../api/service/templates';

export const RegisterAccountScreen: React.FC = () => {
  const ui = useContext(UiContext);
  const idmUi = useContext(IdmUiContext);
  const { t } = useTranslation("ucet");
  const headerConfig: DistaPageHeaderProps = {
    breadcrumbs: [
      {
        title: t("ucet.menu.breadcrumbs.home", "Domů"),
        action: () => (window.location.href = "/ucet/"),
      },
      {
        title: t("ucet.admin.registration.title", "Registrace"),
      },
    ],
    pageTitle: t("ucet.admin.registration.title", "Registrace"),
    //(workflowId ? (workflowId !== "_search" ? " - " + workflowId : "") : "")
    chipsDefinition: [
      //{label: t('chips.approved', 'Approved'), color: 'primary'},
    ],
    actions: [
      // {
      //     title: t('mainpage.actions.primary', 'Primary action'),
      //     action: () => alert('Primary action clicked'),
      //     primary: true,
      // },
      // {title: t('mainpage.actions.action1', 'First action'), action: () => alert('First action clicked')},
      // {title: t('mainpage.actions.action2', 'Second action'), action: () => alert('Second action clicked')},
    ],
  };

  return (
    <DistaContent headerConfig={headerConfig} fullWidth={true}>
      <IntentPicker
        onIntentSelected={(intent) => {
          idmUi.setRegistrationIntent(intent);
          ui.openDialog("registration");
        }}
      />
    </DistaContent>
  );
};
