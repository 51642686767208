import { ProfileType } from "@sista/idm-client";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export function useProfileNames() {
  const { t } = useTranslation("ucet");

  // "EXPERT" | "PROVIDER" | "GESTOR" | "APPLICANT";
  return useCallback(
    (pt: ProfileType) => {
      switch (pt) {
        case "APPLICANT":
          return t("common.profileType.APPLICANT", "Uchazeč / Příjemce");
          break;
        case "GESTOR":
          return t("common.profileType.GESTOR", "Gestor");
          break;
        case "PROVIDER":
          return t("common.profileType.PROVIDER", "Poskytovatel");
          break;
        case "EXPERT":
          return t("common.profileType.EXPERT", "Expert");
          break;
        case "ISTA":
          return t("common.profileType.ISTA", "ISTA");
          break;
      }
      return t("common.profileType.UNKNOWN", "Neznámý");
    },
    [t]
  );
}
