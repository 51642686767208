import { TextField } from "@mui/material";
import { Property } from "csstype";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type SistaTwoAuthInputProps = {
  onCodeProvided?: (token: string) => void;
};

export const SistaTwoAuthInput = (props: SistaTwoAuthInputProps) => {
  const LENGTH = 6;

  const { t } = useTranslation("ucet");

  const [tokenValue, setTokenValue] = useState<string>("");

  // token value is 6 numbers
  const inError = tokenValue.length != LENGTH || isNaN(Number(tokenValue));
  const isEmpty = tokenValue.length == 0;

  useEffect(() => {
    if (props.onCodeProvided && !inError) {
      props.onCodeProvided(tokenValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenValue, inError, props.onCodeProvided]);

  const inputStyle = {
    fontFamily: "monospace",
    fontSize: "1.5em",
    textAlign: "center" as Property.TextAlign,
    letterSpacing: "0.3em",
  };

  return (
    <TextField
      error={inError && !isEmpty}
      placeholder="000000"
      autoComplete={"off"}
      sx={{ width: "18em" }}
      inputProps={{ style: inputStyle }}
      fullWidth={false}
      value={tokenValue}
      onChange={(e) => setTokenValue(e.target.value)}
      helperText={
        inError && !isEmpty
          ? t("ucet.error.codeLength", "Pole musí obsahovat přesně 6 číslic")
          : null
      }
      label={t("ucet.twoAuth.code", "6 místný kód poskytnutý aplikací")}
    />
  );
};
