import { useState } from "react";

export type ControlHandle = {
  callback: VoidFunction;
  setCallback: (callback: VoidFunction) => void;
};

export const useControlHandle = () => {
  const [callback, setCallback] = useState<VoidFunction>(null);
  return {
    callback,
    setCallback,
  };
};
