import { IdmClientContext } from "@sista/idm-client";
import { buildIdmClient } from "@sista/library-stasi";
import { useContext, useMemo } from "react";

export const useIdmClient = () => {
  //console.debug("Getting IdmClient");
  const c = useContext(IdmClientContext);

  if (c == null) {
    throw new Error("IdmClientContext is null");
  }

  const authKeyToUse = c?.loggedAccount?.authKey;
  return useMemo(
    () => buildIdmClient(c.idmRoot, authKeyToUse),
    [authKeyToUse, c.idmRoot]
  );
};
