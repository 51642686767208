import { Button, Typography } from "@mui/material";
import { AlertsContext } from "@sista/dista-ui";
import { IdmClientContext } from "@sista/idm-client";
import {
  Center,
  Right,
  SistaDialog,
  UiContext,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useIdmClient } from "../../../api/utils/useIdmClient";
import { IdmUiContext } from "../../../utils/IdmUiContext";
import { SistaTwoAuthInput } from "../../ui/SistaTwoAuthInput";

export const TwoAuthLoginDialog = () => {
  const idm = useContext(IdmClientContext);
  const idmUi = useContext(IdmUiContext);
  const { openDialog, closeDialogs } = useContext(UiContext);
  const alerts = useContext(AlertsContext);
  const { t } = useTranslation("ucet");

  const idmClient = useIdmClient();

  const [twoAuthCompleteLoginCall, twoAuthCompleteLoginState] =
    useSafeSistaAsync(idmClient.twoAuthCompleteLogin);
  const [twoAuthCode, setTwoAuthCode] = useState<string | null>(null);

  const finishLogin = useCallback(async () => {
    twoAuthCompleteLoginCall({ value: twoAuthCode });
  }, [twoAuthCode, twoAuthCompleteLoginCall]);

  useEffect(() => {
    if (twoAuthCompleteLoginState.error) {
      twoAuthCompleteLoginState.reset();
      // alerts.showError(
      //   t("ucet.login.twoAuthDialog.error", "Kód není platný. Zkuste to znovu.")
      // );
      return;
    }
    if (!twoAuthCompleteLoginState.value) return;
    idm.setLoggedAccount(twoAuthCompleteLoginState.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    alerts.showError,
    idm,
    t,
    twoAuthCompleteLoginState.error,
    twoAuthCompleteLoginState.value,
    twoAuthCompleteLoginState.reset,
  ]);

  return (
    <SistaDialog
      onClose={closeDialogs}
      title={t("ucet.login.twoAuthDialog.title", "Dvoufaktorové přihlášení")}
    >
      <Typography variant={"body1"}>
        {t(
          "ucet.login.twoAuthDialog.info",
          "U vašeho účtu je aktivována dvoufaktorová autorizace. Pro dokončení přihlášení je potřeba zadat kód, který se zobrazuje v mobilní aplikaci Google Authenticator."
        )}
      </Typography>

      <Center sx={{ marginBottom: 4 }}>
        <SistaTwoAuthInput onCodeProvided={(token) => setTwoAuthCode(token)} />
      </Center>

      <Right>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => finishLogin()}
        >
          {t("ucet.login.twoAuthDialog.loginButton", "Dokončit přihlášení")}
        </Button>
      </Right>
    </SistaDialog>
  );
};
