import { Box, Button, Grid, Typography } from "@mui/material";
import { theme } from "@sista/dista-ui";
import { Right, SistaCard } from "@sista/library-stasi";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

export function IstaLoginInfo(props: { onClick: () => void }) {
  const { t } = useTranslation("ucet");

  return (
    <Grid item xs={12} md={12} sx={{ display: { md: "block" } }}>
      <SistaCard sx={{ backgroundColor: theme.palette.background.gray }}>
        <Box sx={{ p: 4 }}>
          <Typography variant={"body1"}>
            <Trans i18nKey={"ucet.home.fromIstaDescription"}>
              <p>
                <strong>Informace pro stávající uživatele ISTA:</strong> Vaše
                uživatelské účty byly zavedeny do nového systému SISTA, který se
                pro přihlášení do ISTA bude nadále používat. Pokud se
                přihlašujete Google účtem, nic se pro vás nemění. Pokud se ale
                přihlašujete pomocí e-mailu, je nutné si nejprve změnit heslo
                pomocí funkce pro obnovu zapomenutého hesla.
              </p>

              <p>
                <strong>
                  Důležitá informace pro uživatele využívající přihlašování přes
                  eduID:
                </strong>{" "}
                Do nového systému SISTA není možné se přihlásit prostřednictvím
                eduID. Uživatelům, kteří tento způsob přihlášení využívají, jsme
                nastavili e-mail (uvedený v ISTA) jako přihlašovací jméno. Toto
                opatření by mělo zajistit bezproblémové přihlášení.
              </p>

              <p>
                Bližší informace s návodem pro první přihlášení uživatelů{" "}
                <strong>ISTA</strong> naleznete{" "}
                <a
                  target="_blank"
                  href="https://www.tacr.cz/ista-prvni-prihlaseni/"
                  rel="noreferrer"
                >
                  zde
                </a>
                .
              </p>
              <p>
                Pokud máte nějaké další dotazy, nebo si nevíte rady, je možné se
                obrátit na{" "}
                <a
                  target="_blank"
                  href={"https://helpdesk.tacr.cz/index.php?a=add&category=58"}
                  rel="noreferrer"
                >
                  helpdesk TA ČR
                </a>
                .
              </p>
            </Trans>
          </Typography>
          <Right>
            <Button variant={"contained"} onClick={props.onClick}>
              {t("ucet.home.fromIsta.ctaButton", "Nastavit heslo")}
            </Button>
          </Right>
        </Box>
      </SistaCard>
    </Grid>
  );
}
