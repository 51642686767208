/* eslint-disable no-console */

import { IdmClientContext, ProfileType } from "@sista/idm-client";
import { isLocal, Organization, useSafeSistaAsync } from "@sista/library-stasi";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { featureOrganizationAdmin } from "./features";
import { useRejstrikClient } from "../api/utils/useRejstrikClient";

type PossibleIntent = undefined | ProfileType;

export type IdmUiContextType = {
  registrationIntent: PossibleIntent;
  setRegistrationIntent: (intent: PossibleIntent) => void;
  metadata?: { [key: string]: any };
  loggedAccountOrganizations?: Organization[];
};

const attemptedToSwitchProfile = false;

export const IdmUiContext = createContext<IdmUiContextType | null>(null);

IdmUiContext.displayName = "IdmUiContext";

type IdmUiContextProviderProps = {
  children: React.ReactNode;
};

export type Metadata = { [key: string]: any };

export const IdmUiContextProvider = (props: IdmUiContextProviderProps) => {
  const [registrationIntent, setRegistrationIntent] =
    useState<PossibleIntent>(null);

  const { loggedAccount } = useContext(IdmClientContext);

  const { getMyOrganizations } = useRejstrikClient();

  const [getMyOrganizationsCall, getMyOrganizationsState] =
    useSafeSistaAsync(getMyOrganizations);

  useEffect(() => {
    if (loggedAccount != null && featureOrganizationAdmin) {
      getMyOrganizationsCall(null);
    }
  }, [getMyOrganizationsCall, loggedAccount]);

  const fetchMetadataImpl = useCallback(async () => {
    const response = await fetch("/metadata/ucet/api/v1/config/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let metadata = await response.json();
    if (isLocal()) {
      metadata = {
        REACT_APP_RECAPTCHA: "6Ld5mDgkAAAAAJiqsDNHhjL0G-N-4zP4kUTaOOsj",
        REACT_APP_GOOGLE_CLIENT_ID:
          "1081095464154-o7ap6papqion7rodptr0btjv2sv2hlvm.apps.googleusercontent.com",
      };
    }
    return metadata as Metadata;
  }, []);

  const [fetchMetadataCall, fetchMetadataState] =
    useSafeSistaAsync(fetchMetadataImpl);

  useEffect(() => {
    // logi("dsadasdasa");
    fetchMetadataCall(null);
  }, [fetchMetadataCall]);

  const context: IdmUiContextType = useMemo(
    () => ({
      registrationIntent: registrationIntent,
      setRegistrationIntent: setRegistrationIntent,
      metadata: fetchMetadataState.value,
      loggedAccountOrganizations: getMyOrganizationsState.value,
    }),
    [
      fetchMetadataState.value,
      getMyOrganizationsState.value,
      registrationIntent,
    ]
  );

  return (
    <IdmUiContext.Provider value={context}>
      {props.children}
    </IdmUiContext.Provider>
  );
};
