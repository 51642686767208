import { DistaSelect } from "@sista/dista-ui";
import {
  countryCodes,
  countryLabels,
  FormButton,
  Person,
  Right,
  SistaAutocompleteElement,
  SistaFormHeader,
  SistaPlainDateElement,
  useDateValidation,
  useEmailValidation,
  useMaxLengthValidation,
  useMinLengthValidation,
  useNameValidation,
  usePhoneNumberValidation,
  useReasonableDateValidation,
  useRequiredValidation,
  useRodneCisloValidation,
} from "@sista/library-stasi";
import { useFormContext } from "react-hook-form";
import { CheckboxElement, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { useGender } from "../../hooks/useGender";

type ExtendedPerson = Person & { withoutBirthNumber: boolean };

export const PersonDataForm = () => {
  const form = useFormContext<ExtendedPerson>();

  const { t } = useTranslation("ucet");

  const required = useRequiredValidation();
  const maxLength = useMaxLengthValidation(129);
  const minLength = useMinLengthValidation(2);
  const phone = usePhoneNumberValidation();
  const email = useEmailValidation();
  const rc = useRodneCisloValidation();
  const name = useNameValidation();
  const date = useDateValidation();
  const birth = useReasonableDateValidation();

  const { genderName, genders } = useGender();

  //   t("person.detail.contact.headline", "Kontaktní údaje"),
  //     getPersonData,
  //     savePersonData,
  //     PersonDataForm
  // ),

  //     detailItem(t("common.firstName", "Jméno"), props.person.firstName),
  //     detailItem(t("common.lastName", "Příjmení"), props.person.lastName),
  //     detailItem(t("ucet.person.phone", "Telefon"), props.person.phone),
  //     detailItem(
  //       t("ucet.person.birthDate", "Datum narození"),
  //       formatPlainDate(props.person.birthDate)
  //     ),
  //     detailItem(
  //       t("ucet.person.nationalIdNumber", "Rodné číslo"),
  //       props.person.nationalIdNumber
  //     ),
  //     detailItem(t("ucet.person.gender", "Pohlaví"), props.person.gender),

  //    Gender: "M" | "F" | "O";

  const g = form.watch("gender");
  const birthNumberMandatory = form.watch("withoutBirthNumber") != true;

  return (
    <>
      {
        <SistaFormHeader
          title={t("person.detail.basic.headline", "Osobní údaje")}
          subtitle={null}
        />
      }

      <TextFieldElement
        name={"titlesBefore"}
        label={t("ucet.person.titlesBefore", "Tituly před jménem")}
        validation={{ ...minLength, ...maxLength }}
      />

      <TextFieldElement
        name={"titlesAfter"}
        label={t("ucet.person.titlesAfter", "Tituly za jménem")}
        validation={{ ...minLength, ...maxLength }}
      />

      {/*<TextFieldElement*/}
      {/*  name={"phone"}*/}
      {/*  label={t("common.phone", "Telefon")}*/}
      {/*  validation={{ ...minLength, ...maxLength, ...phone }}*/}
      {/*/>*/}

      <SistaPlainDateElement
        name={"birthDate"}
        label={t("ucet.person.birthDate", "Datum narození")}
        validation={{ ...date, ...birth, ...required }}
      />
      {birthNumberMandatory && (
        <TextFieldElement
          name={"nationalIdNumber"}
          label={t("ucet.person.nationalIdNumber", "Rodné číslo")}
          validation={{ ...rc, ...required }}
        />
      )}

      <CheckboxElement
        name={"withoutBirthNumber"}
        label={t("ucet.person.withoutBirthNumber", "Nemám české rodné číslo")}
      />

      <SistaAutocompleteElement
        optionValues={countryCodes}
        optionLabels={countryLabels}
        name={"nationality"}
        validation={{ ...required }}
        label={t("ucet.person.nationality", "Státní příslušnost")}
        multiple={false}
      />

      <DistaSelect
        notched={false}
        autoWidth
        fullWidth
        label={t("ucet.person.gender", "Pohlaví")}
        selectedMenuItem={g}
        menuItems={genders.map((g) => ({
          id: g,
          text: genderName(g),
        }))}
        onChange={(e) => form.setValue("gender", e.target.value as any)}
        //variant="outlined"
      />

      <Right sx={{ marginTop: 4, marginBottom: 4 }}>
        <FormButton variant={"contained"} type="submit">
          {t("common.save", "Uložit")}
        </FormButton>
      </Right>
    </>
  );
};
