import { Box } from "@mui/material";
import { IdmClientContext } from "@sista/idm-client";
import { isDev, logi } from "@sista/library-stasi";
import {
  SistaTaskBanner,
  SistaTaskCount,
  SistaTaskPresent,
  SistaTaskSolutionComponentProps,
  SistaWorkflowProvider,
  SistaWorkflowThermometer,
  useSistaTaskCount,
  useSistaTaskPresent,
} from "@sista/service-workflow-ui";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const DemoScreen = () => {
  const { t } = useTranslation("workflow-ui");
  const { loggedAccount } = useContext(IdmClientContext);
  const [myValue, setMyValue] = useState("MAq3kF02q8");

  const processValueChange = (value: string) => {
    setMyValue(value);
    logi(value);
  };

  logi("Logged profile: ", loggedAccount?.activeProfile);

  useEffect(() => {
    logi("Demo screen mounted");
    throw new Error("Demo error");
    return () => {
      logi("Demo screen unmounted");
    };
  }, []);

  if (!isDev()) return <div>Not available in production</div>;

  return (
    <>
      <SistaWorkflowProvider
        loggedAuthKey={loggedAccount!.authKey}
        workflowContext={["urn:sista:profiles:2q7ZFevxBY"]}
      >
        <h1>DEMO</h1>
        <DemoContentScreen />
      </SistaWorkflowProvider>
    </>
  );
};

const DemoSolutionComponent = (props: SistaTaskSolutionComponentProps) => {
  const { solveTask, task, closeSolutionUi, StandardSolutionComponent } = props;
  const [showDefaultSolutionForm, setShowDefaultSolutionForm] = useState(false);

  if (showDefaultSolutionForm) return <StandardSolutionComponent />;

  return (
    <>
      <p>{JSON.stringify(task)}</p>
      <button onClick={() => setShowDefaultSolutionForm(true)}>
        Zobrazit řešení
      </button>
      <button onClick={closeSolutionUi}>Zavřít</button>
      {task.taskTemplateId == "expert_profile_activation.phaseOneCheck" && (
        <button onClick={() => solveTask("backToPhaseOneFill")}>Vyresit</button>
      )}
    </>
  );
};

/**
 *
 * Příklad použití dalších komponent. Všechny se opírají o data načtená v SistaWorkflowContextu, viz SistaWorkflowProvider.
 *
 * @constructor
 */
export const DemoContentScreen = () => {
  logi("Count: ", useSistaTaskCount());
  logi("Present: ", useSistaTaskPresent());

  return (
    <>
      Počet úkolů: <SistaTaskCount />
      <Box mb={2} />
      Z toho objednávek: <SistaTaskCount workflowId={"expert_order"} />
      <Box mb={2} />
      <SistaTaskPresent>Ano, mám tááásky.</SistaTaskPresent>
      <Box mb={2} />
      <SistaWorkflowThermometer />
      <Box mb={2} />
      <SistaTaskBanner SolutionComponent={DemoSolutionComponent} />
    </>
  );
};
