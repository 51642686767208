import { IdmClientContext } from "@sista/idm-client";
import { logi, UiContextProvider } from "@sista/library-stasi";
import React, { useContext } from "react";

import { AnonymousContent } from "./AnonymousContent";
import { FullAccountContent } from "./FullAccountContent";
import { SemiAccountContent } from "./SemiAccountContent";
import { useLocalNavigation } from "../../hooks/useLocalNavigation";

export const Content: React.FC = () => {
  const idm = useContext(IdmClientContext);
  const localNavigationConfig = useLocalNavigation();
  const hasIstaProfile = idm.loggedAccount?.profiles.some(
    (p) => p.type === "ISTA"
  );
  const hasOneProfile = idm.loggedAccount?.profiles.length === 1;

  // User is semi-authorized, or he has only one profile and it is ISTA
  const semiAuthorized =
    (idm.loggedAccount?.flags?.length ?? 0) > 0 ||
    (hasIstaProfile && hasOneProfile);

  // Takovy jako testovani:
  // useEffect(() => {
  //   if (idm.loggedAccount.flags.indexOf("emailVerificationNeeded") < -0) {
  //     idm.loggedAccount.flags.push("emailVerificationNeeded");
  //     idm.setLoggedAccount(idm.loggedAccount);
  //   }
  // }, [idm.loggedAccount.flags, idm.setLoggedAccount]);

  logi("Logged profile: ", idm.loggedAccount?.activeProfile);

  return (
    <UiContextProvider>
      {/* ===== ANONYMOUS USER ================ */}
      {!idm.loggedAccount && <AnonymousContent />}

      {/* ===== SEMI AUTHORIZED USER ================ */}
      {idm.loggedAccount && semiAuthorized && <SemiAccountContent />}

      {/* ===== FULLY AUTHORIZED USER ================ */}
      {idm.loggedAccount && !semiAuthorized && <FullAccountContent />}
    </UiContextProvider>
  );
};
