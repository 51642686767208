import { Box, Divider, Stack, Typography } from "@mui/material";
import { IdmClientContext } from "@sista/idm-client";
import {
  Profile,
  Relation,
  SistaDialog,
  SistaFormHeader,
  UiContext,
} from "@sista/library-stasi";
import { SistaChip } from "@sista/sista-chip";
import i18next from "i18next";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ConfigContext } from "../../../hooks/ConfigContext";

type OrganizationProjectRolesDialogProps = {
  profile: Profile;
  onDone: () => unknown;
};

export const OrganizationProjectRolesDialog = (
  props: OrganizationProjectRolesDialogProps
) => {
  const relationsByRoleMap = useMemo(() => {
    const relations1 = [
      ...((props.profile as any)._viewData.relations as Relation[]).filter(
        (r) => r.refEntityType == "projects"
      ),
    ];
    const byRoles = new Map<string, Relation[]>();
    relations1.forEach((r) => {
      const role = r.role;
      if (!byRoles.has(role)) {
        byRoles.set(role, []);
      }
      byRoles.get(role)?.push(r);
    });
    return byRoles;
  }, [props]);

  const knownRoles = useMemo(() => {
    const roles = new Set<string>();
    relationsByRoleMap.forEach((_, role) => roles.add(role));
    const sorted = [...roles].sort();
    return sorted;
  }, [relationsByRoleMap]);

  const { loggedAccount } = useContext(IdmClientContext);

  const { closeDialogs } = useContext(UiContext);

  const done = useCallback(() => {
    props.onDone();
    closeDialogs();
  }, [closeDialogs, props]);

  const { roleName } = useContext(ConfigContext);
  const { t } = useTranslation("ucet");

  return (
    <SistaDialog
      title={t(
        "ucet.organization.projectRolesDialogTitle",
        "Přehled zapojení uživatele"
      )}
      onClose={done}
    >
      <SistaFormHeader
        title={props.profile.firstName + " " + props.profile.lastName}
      />
      <Divider />
      <Stack direction="column" spacing={2} marginBottom={4}>
        {knownRoles.length == 0 && (
          <Typography variant="body1">
            {t(
              "ucet.organization.noProjectRoles",
              "Uživatel není zapojen v žádných projektech"
            )}
          </Typography>
        )}
        {knownRoles.map((role) => (
          <Box key={"role"}>
            <Typography variant={"h3"} marginTop={2}>
              {roleName(role, i18next.language)}
            </Typography>
            <Box>
              {relationsByRoleMap.get(role)?.map((r) => {
                return (
                  <SistaChip
                    key={r.id}
                    authToken={loggedAccount?.authKey}
                    urn={"urn:sista:projects:" + r.refEntityId}
                  />
                );
              })}
            </Box>
          </Box>
        ))}
      </Stack>
    </SistaDialog>
  );
};
