import { Box, Stack } from "@mui/material";
import { AlertsContext } from "@sista/dista-ui";
import {
  Center,
  Right,
  SistaCodeInput,
  SistaDialog,
  SistaFormHeader,
  SmartButton,
  UiContext,
  useEmailValidation,
  usePager,
  useRequiredValidation,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useCallback, useContext } from "react";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { useIdmClient } from "../../../api/utils/useIdmClient";
import { SistaPasswordInput } from "../../ui/SistaPasswordInput";
import { TextLanguageSwitcher } from "../../ui/TextLanguageSwitcher";

type LostPasswordForm = {
  email: string;
  token: string;
  newPassword: string;
};

export const ForgottenPasswordDialog = () => {
  const form = useForm<LostPasswordForm, unknown>();
  const alerts = useContext(AlertsContext);
  const { openDialog } = useContext(UiContext);
  const { t } = useTranslation("ucet");
  const pager = usePager(0);

  const required = useRequiredValidation();
  const email = useEmailValidation();
  const idmClient = useIdmClient();

  const [requestTokenCall, requestTokenState] = useSafeSistaAsync(
    useCallback(
      async (dto: LostPasswordForm) => {
        await idmClient.lostPasswordRequestToken({
          email: dto.email,
          inOnboarding: false,
        });
        pager.nextPage();
      },
      [idmClient, pager]
    )
  );

  const [resetPasswordCall, resetPasswordState] = useSafeSistaAsync(
    useCallback(
      async (dto: LostPasswordForm) => {
        const result = await idmClient.lostPasswordChange(dto);
        if (result) {
          alerts.showSuccess(
            t("ucet.forgottenPassword.success", "Heslo bylo úspěšně změněno")
          );
          openDialog("login");
        } else {
          alerts.showError(
            t(
              "ucet.forgottenPassword.failed",
              "Heslo se nepodařilo změnit, kód není pravděpodobně správný."
            )
          );
        }
      },
      [alerts, idmClient, t, openDialog]
    )
  );

  const forgottenPassword = useCallback(() => {
    openDialog("forgottenPassword");
  }, [openDialog]);

  const doSubmitCall = pager.page == 0 ? requestTokenCall : resetPasswordCall;
  const doSubmitState =
    pager.page == 0 ? requestTokenState : resetPasswordState;

  return (
    <SistaDialog
      title={t("ucet.forgottenPassword.dialogTitle", "Zapomenuté heslo")}
      onClose={() => openDialog(null)}
      onBack={pager.page > 0 ? pager.prevPage : undefined}
    >
      <SistaFormHeader
        title={t("ucet.forgottenPassword.title", "Obnovení hesla")}
        subtitle={
          pager.page == 0
            ? t(
                "ucet.forgottenPassword.subtitle",
                "Zadejte e-mail, který jste použili při registraci. Na tento e-mail Vám bude zaslán kód pro obnovení hesla."
              )
            : t(
                "ucet.forgottenPassword.changeSubtitle",
                "Zkopírujte kód, který vám byl zaslán a použijte ho pro změnu hesla."
              )
        }
      />

      <FormContainer onSuccess={doSubmitCall} formContext={form}>
        {/*<BlockLeave/>*/}
        <Stack direction={"column"}>
          {pager.page == 0 && (
            <TextFieldElement
              name="email"
              autoComplete={"email"}
              label={t("ucet.form.email", "E-mail")}
              validation={{ ...required, ...email }}
            />
          )}

          {pager.page == 1 && (
            <>
              <Center sx={{ marginBottom: 4 }}>
                <SistaCodeInput name="token" />
              </Center>
              <SistaPasswordInput
                newPassword={true}
                name="newPassword"
                label={t("ucet.form.newPassword", "Nové heslo")}
              />
            </>
          )}

          <Right sx={{ marginTop: 4 }}>
            <SmartButton state={doSubmitState}>
              {t("common.continue", "Pokračovat")}
            </SmartButton>
          </Right>

          <Box marginTop={4}>
            <TextLanguageSwitcher />
          </Box>
        </Stack>
      </FormContainer>
    </SistaDialog>
  );
};
