import { Box, DialogContent } from "@mui/material";
import {
  AlertsBarContainer,
  AlertsBarVariant,
  AlertsContext,
  DistaDialog,
  DistaDialogHeader,
} from "@sista/dista-ui";
import {
  Organization,
  OrganizationRegistrationRequestDTO,
  Relation,
  RelationDTO,
  UiContext,
  useEmailValidation,
  useMaxLengthValidation,
  useMinLengthValidation,
  usePhoneNumberValidation,
  useRequiredValidation,
} from "@sista/library-stasi";
import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { OrganizationCreateDelegateContent } from "./OrganizationCreateDelegateContent";
import { OrganizationRequestToJoinDialog } from "./OrganizationRequestToJoinDialog";
import { OrganizationSearchByIcoContent } from "./OrganizationSearchByIcoContent";
import { useControlHandle } from "../../../utils/controlHandle";

export type OrganizationFindForm = {
  idNumber?: string;
};

export type OrganizationEditDialogProps = {
  organization?: Organization;
  registration?: OrganizationRegistrationRequestDTO;
};

export const OrganizationRegisterDialog = (
  props: OrganizationEditDialogProps
) => {
  const { closeDialogs } = useContext(UiContext);
  const alerts = useContext(AlertsContext);
  const { t } = useTranslation("ucet");
  const navigate = useNavigate();

  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>(props.organization);
  const [selectedOrganizationRelations, setSelectedOrganizationRelations] =
    useState<RelationDTO[]>(null);

  const phone = usePhoneNumberValidation();
  const email = useEmailValidation();
  const maxLength = useMaxLengthValidation(37);
  const maxInfoLength = useMaxLengthValidation(1000);
  const minLength = useMinLengthValidation(2);
  const required = useRequiredValidation();
  const backHandler = useControlHandle();

  const phaseFound = selectedOrganization != null;
  const phaseVerified = selectedOrganization?.verified ?? false;

  const closeDialog = useCallback(() => {
    navigate("/");
    closeDialogs();
  }, [closeDialogs, navigate]);

  return (
    <DistaDialog onClose={closeDialog} open={true}>
      <Box>
        <DistaDialogHeader
          onClose={closeDialog}
          onBack={backHandler.callback}
          showBackButton={backHandler.callback != null}
          title={t(
            "ucet.organization.connectToProfile",
            "Propojení s organizací"
          )}
        />
        <AlertsBarContainer
          id="alerts-in-dialog"
          variant={AlertsBarVariant.relative}
        />
        <DialogContent>
          {!phaseFound ? (
            <OrganizationSearchByIcoContent
              onFoundOrganization={setSelectedOrganization}
              onFoundRelationsInOrganization={setSelectedOrganizationRelations}
            />
          ) : null}
          {phaseFound && !phaseVerified ? (
            <>
              <OrganizationCreateDelegateContent
                organization={selectedOrganization}
                registration={props.registration}
                backControl={backHandler}
              />
            </>
          ) : null}
          {phaseFound && phaseVerified ? (
            <OrganizationRequestToJoinDialog
              organization={selectedOrganization}
              onNewIco={() => {
                return setSelectedOrganization(null);
              }}
            />
          ) : null}
        </DialogContent>
      </Box>
    </DistaDialog>
  );
};
