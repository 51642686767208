import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { GlobalStyles } from "@mui/system";
import { AlertsProvider, theme } from "@sista/dista-ui";
import { LanguageProvider, SistaUiWrapper } from "@sista/fe-commons";
import { IdmClientContextProvider } from "@sista/idm-client";
import { LoggedAccountDTO } from "@sista/library-stasi";
import i18next from "i18next";
import React from "react";
import { BrowserRouter } from "react-router-dom";

import { Content } from "./components/content/Content";
import { IdmUiContextProvider } from "./utils/IdmUiContext";

type AppProps = {
  account: LoggedAccountDTO | null;
};

const globalStyles = (
  <GlobalStyles
    styles={
      ".dummy { max-width: 1400px;} .height-fullscreen { height: calc(100vh - 68px)} "
    }
  />
);

const App: React.FC<AppProps> = ({ account }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <IdmClientContextProvider
        clientId={process.env.REACT_APP_CLIENT_ID}
        loggedAccount={account}
        idmRoot={process.env.REACT_APP_IDM_ROOT || "/idm"}
        ucetRoot={process.env.REACT_APP_UCET_ROOT || "/ucet"}
        workAsAccountProvider={true}
      >
        <IdmUiContextProvider>
          <LanguageProvider>
            <React.Fragment key={i18next.language}>
              <SistaUiWrapper>
                <AlertsProvider>
                  <BrowserRouter
                    key={i18next.language}
                    basename={process.env.REACT_APP_BASEPATH}
                  >
                    {globalStyles}
                    <Content />
                  </BrowserRouter>
                </AlertsProvider>
              </SistaUiWrapper>
            </React.Fragment>
          </LanguageProvider>
        </IdmUiContextProvider>
      </IdmClientContextProvider>
    </ThemeProvider>
  );
};

export default App;
