export const MoodBlock = () => {
  return (
    <img
      src={process.env.REACT_APP_BASEPATH + "/images/mood.png"}
      style={{ width: "560px", height: "320px" }}
    />
  );
};

export default MoodBlock;
