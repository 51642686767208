import { LanguageContext } from "@sista/fe-commons";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

export const TextLanguageSwitcher = () => {
  const { language, changeLanguage } = useContext(LanguageContext);
  const { t } = useTranslation("ucet");

  const changeLanguageToCs = useCallback(
    () => changeLanguage("cs"),
    [changeLanguage]
  );
  const changeLanguageToEn = useCallback(
    () => changeLanguage("en"),
    [changeLanguage]
  );
  const isCs = language === "cs";

  return <div />;
  // <Box
  //   sx={{
  //     display: "flex",
  //     alignItems: "center",
  //     justifyContent: "center",
  //   }}
  // >
  //   {isCs ? (
  //     <Typography variant={"button"} paddingX={2}>
  //       {t("common.cs", "Čeština")}
  //     </Typography>
  //   ) : (
  //     <Button
  //       variant={"text"}
  //       onClick={changeLanguageToCs}
  //       sx={{ marginLeft: 2, flexGrow: 0, px: 2, m: 0 }}
  //     >
  //       {t("common.cs", "Čeština")}
  //     </Button>
  //   )}
  //   <Divider orientation="vertical" flexItem />
  //   {!isCs ? (
  //     <Typography variant={"button"} paddingX={2}>
  //       {t("common.en", "English")}
  //     </Typography>
  //   ) : (
  //     <Button
  //       variant={"text"}
  //       onClick={changeLanguageToEn}
  //       sx={{ marginLeft: 2, flexGrow: 0, px: 2, m: 0 }}
  //     >
  //       {t("common.en", "English")}
  //     </Button>
  //   )}
  // </Box>
};
