import { IdmClientContext } from "@sista/idm-client";
import { buildRejstrikClient } from "@sista/library-stasi";
import { useContext, useMemo } from "react";

export const useRejstrikClient = () => {
  const c = useContext(IdmClientContext);
  if (c == null) {
    throw new Error("IdmClientContext is null");
  }
  return useMemo(
    () =>
      buildRejstrikClient(
        process.env.REACT_APP_REJSTRIK_ROOT,
        c?.loggedAccount?.authKey
      ),
    [c?.loggedAccount]
  );
};
